import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from '@material-ui/core/Hidden';

function MaterialResultadoComponent(props) {
  const classes = useStyles();
  const { name, cantidad, unidad, otraCantidad, otraUnidad, esComerciable } = props;

  return (
    <div className={classes.containerMaterial}>
      <Grid container spacing={4}>
      <Hidden mdDown>
        <Grid item xs={2} md={2} only ='md'></Grid>
      </Hidden>
        
        <Grid item className={classes.name} xs={4} md={3}>
          <Box
            display="flex"
            justifyContent="start"
            alignItems="start"
            borderRight={0}
          >
            <Typography className={classes.textName}>{name} <span className={classes.esComerciable}>{!esComerciable ? '(*)' : ''}</span></Typography>
          </Box>
        </Grid>
        <Grid item className={classes.sectionCantidad} xs={3} md={2}>
          <Box className={classes.boxName} borderLeft={1}>
            <Typography className={classes.textCantidad}>{cantidad}</Typography>
            <Typography className={classes.textCantidad}>{unidad}</Typography>
          </Box>
        </Grid>
        {otraCantidad !== "NA" ? (
          <Grid item className={classes.sectionOtraCantidad} xs={2} md={1}>
            <Box
              justifyContent="center"
              alignItems="center"
              borderRight={0}
              className={classes.boxIcon} 
            >
              <ArrowForwardIosIcon className={classes.icon} />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={2} />
        )}
        {otraCantidad !== "NA" ? (
          <Grid item className={classes.sectionOtraCantidad} xs={3} md={2}>
            <Box borderRight={1} borderLeft={4} borderTop={1} borderBottom={1} borderRadius={5} className={classes.boxOtraCantidad}>
              <Typography className={classes.textOtraCantidad}>
                {otraCantidad}
              </Typography>
              <Typography className={classes.textOtraUnidad}>
                {otraUnidad}
              </Typography>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={4} md={4}/>
        )}

      <Hidden mdDown>
        <Grid item xs={2} md={2} only ='md'></Grid>
      </Hidden>
      </Grid>
    </div>
  );
}

export default MaterialResultadoComponent;

const useStyles = makeStyles((theme) => ({
  textName: {
    fontSize: "21px",
    fontWeight: "900",
    color: "black",
  },
  esComerciable: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#E2231A",
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  sectionCantidad: {
    textAlign: "center",
  },
  boxName: {
    textAlign: "center",
    paddingLeft: 30,
  },
  textCantidad: {
    fontSize: "21px",
    fontWeight: "500",
    color: "black",
    textAlign: "left",
  },
  icon: {
    color: "#979797",
  },
  textOtraCantidad: {
    fontSize: "19px",
    fontWeight: "900",
    color: "black",
    textAlign: "left",
  },
  textOtraUnidad: {
    fontSize: "20px",
    fontWeight: "400",
    color: "black",
    textAlign: "left",
  },
  boxOtraCantidad: {
      borderColor:"#FDE300",
      paddingLeft: 15,
      paddingTop: 5,
      paddingBottom: 5,
  },
  boxIcon: {
      paddingTop:15
  },
  containerMaterial: {
    paddingTop:15,
    paddingBottom: 15,
  }
}));
