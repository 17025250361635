import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AgrupacionComponent from "../Resultados/AgrupacionComponent";
import MaterialResultadoComponent from "../Resultados/MaterialResultadoComponent";

export default function FormResultDeck(props) {
  const classes = useStyles();

  const { resultado } = props;
  const [campos, setCampos] = React.useState([]);

  useEffect(() => {
    if (campos.length === 0) {
      let nuevoObjeto = {};
      let aux = [];
      let agrupacion = "";

      resultado.forEach((x) => {
        if (x.agrupacion !== agrupacion) {
          agrupacion = x.agrupacion;
          nuevoObjeto = {
            agrupacion: x.agrupacion,
            resultado: [],
          };
        }

        nuevoObjeto.resultado.push({
          id: x.id,
          nombre: x.nombre,
          unidades: x.unidades,
          paquetes: x.paquetes,
          esComerciable: x.esComerciable
        });

        if (nuevoObjeto.resultado.length === 4) {
          aux.push(nuevoObjeto);
          agrupacion = "";
        }
      });
      setCampos(aux);
    }
  });

  return (
    <div className="container">
        {campos &&
          campos.map((output, index) => {
            return (
              <div key={index}>
                <div className={classes.agrupacion}>
                  <AgrupacionComponent agrupacion={output.agrupacion} />
                </div>
                {output.resultado.map((x, i) => {
                  return (
                    <div key={i} className={classes.resultadoMaterial}>
                      <MaterialResultadoComponent
                        name={x.nombre}
                        cantidad={x.unidades}
                        unidad={"unidades"}
                        otraCantidad={"NA"}
                        otraUnidad={"NA"}
                        esComerciable={x.esComerciable}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 5,
    overflow: "hidden",
    padding: theme.spacing(0, 35),
  },
  titleResultPage: {
    fontSize: "30px",
    fontWeight: "bold",
    color: "#022A3A",
  },
  sectionSubtitle: {
    paddingTop: 20,
    paddingbottom: 20,
  },
  subtitle: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "black",
  },
  containerResultPage: {
    position: "relative",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(8),
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  buttonBackInit: {
    textAlign: "end",
  },
  sectionObra: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  nombreObra: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  agrupacion: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  resultadoMaterial: {
    paddingTop: 20,
    paddingBottom: 20,
  },
}));
