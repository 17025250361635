import React, {Component} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFacebookF ,faLinkedin, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

export default class Footer extends Component {
    render() {
        return (
            <footer className="row page-footer font-weight-light footer-container">
                <div className="col-md-12">
                    <div className="footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-12">
                                    <span className="footer-info">
                                        <h3>¿DÓNDE ESTAMOS?</h3>
                                        <p><span>A.D. Barbieri (ex Luis M. Drago) 1382</span></p>
                                        <p><span>PARQUE INDUSTRIAL ALMIRANTE BROWN (1852)</span></p>
                                        <p><span>ALTE. BROWN - BUENOS AIRES - ARGENTINA</span></p>
                                    </span>
                                    <span>
                                        <a href="https://servicios1.afip.gov.ar/clavefiscal/qr/response.aspx?qr=fuP1CgELgzOIX6k97tiP-w,," target="_blank">
                                            <img className="data-fiscal" src={require('../assets/images/afip.png')} alt="Logo" />
                                        </a>
                                    </span>
                                </div>
                                <div className="col-md-4 col-12">
                                    <span className="footer-info">
                                        <h3>¿QUIÉNES SOMOS?</h3>
                                        <p>Somos una empresa <strong>familiar y global</strong> que nació en Argentina en 1953. Arduín Darío Barbieri, nuestro fundador, fue referente nacional en actividades de Responsabilidad Social Empresarial, lo que grabó en nuestra empresa el sueño y el trabajo permanente por <strong>mejorar la vida de las personas</strong>.<br/><br/>Con más de 65 años continuamos ofreciendo <strong>soluciones constructivas para obras eficientes</strong> formadas por productos de la más alta calidad y fabricados con la última tecnología.<br/><br/>Desde el comienzo, nuestras acciones y decisiones diarias se encuentran guiadas por <strong>nuestros valores</strong>: Honestidad, Transparencia, Respeto, Solidaridad y Compromiso.</p>
                                    </span>
                                </div>
                                <div className="col-md-4 col-12">
                                    <span className="footer-info">
                                        <h3>CONTACTO</h3>
                                        <p><span>T . FAX (5411) 4136-4000 (LÍNEAS ROTATIVAS)</span></p>
                                        <p><span>4299-3503 | 4299-3705 | 5083-1336</span></p>
                                        <p><span>LÍNEA ALT.: 5083-1560</span></p>
                                        <p><a href="https://www.adbarbieri.com/contacto?hsLang=es" target="_blank"> Envianos tu consulta</a></p>
                                    </span>
                                    <span className="footer-social">
                                        <ul className="social">
                                            <li><a href="https://www.facebook.com/barbieriarg" target="_blank"><FontAwesomeIcon icon={faFacebookF} color="white" /></a></li>
                                            <li><a href="https://www.instagram.com/barbieriarg/" target="_blank"><FontAwesomeIcon icon={faInstagram} color="white" /></a></li>
                                            <li><a href="https://twitter.com/barbieriarg" target="_blank"><FontAwesomeIcon icon={faTwitter} color="white" /></a></li>
                                            <li><a href="https://www.linkedin.com/company/barbieri" target="_blank"><FontAwesomeIcon icon={faLinkedin} color="white" /></a></li>
                                            <li><a href="http://www.youtube.com/Barbieri1953" target="_blank"><FontAwesomeIcon icon={faYoutube} color="white" /></a></li>
                                        </ul>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
