import axiosInstance from '../../helpers/axiosInstance'

export default function descargarExcel (inputs, nombreArchivo) {
    return axiosInstance.request({method:'POST', url: 'excel',
    data:inputs, responseType: 'blob' })
    .then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', nombreArchivo+'.xlsx');
       document.body.appendChild(link);
       link.click();
    });
}