import React, { useEffect } from "react";
import "./style.module.scss";
import CheckBoxComponent from "../../components/CheckboxComponent";
import { INPUT_DATA_URI } from "../../routes";
import { STEEL_FRAME_URI } from "../../routes";
import LoadingPage from "../../components/LoadingPage";
import DescriptionAlerts from "../../components/DescriptionAlerts";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

export const SISTEMAS_SELECCIONADOS = "sistemas";
export const STEEL_FRAME_DATA = "steelFrameData";

export const DATA_INPUT_STEEL_FRAME = "data_input_steelFrame";

function HomePage(props) {
  const classes = useStyles();
  const [flag, setFlag] = React.useState(false);
  const [sistemaActivo, activarSistema] = React.useState("");
  const [seleccionados, setSeleccionados] = React.useState({ myArr: [] });
  let [loading, setLoading] = React.useState(true);
  const [messageError, setMessageError] = React.useState("");

  const [state, setState] = React.useState({
    sistemas: [
      // {
      //   id: 1,
      //   nombre: "Deck",
      //   descripcion:
      //     "El deck de PVC brinda una solución definitiva a solados en sectores de piscinas, terrazas, pasarelas, semicubiertos, espacios interiores y todos aquellos lugares donde se requiera un piso resistente a la intemperie y sin mantenimiento. Es un producto fabricado con 100% PVC rígido extruido, con un diseño único que permite una rápida colocación, de fácil limpieza y amigable con el medio ambiente.",
      //   slug: "deck",
      //   activo: true,
      //   isChecked: false,
      // },
      {
        id: 3,
        nombre: "Drywall Cielorraso",
        descripcion:
          "Nueva línea de perfiles de acero galvanizado para la construcción en seco, 100% moleteados, fabricados y certificados bajo la norma IRAM IAS U 500-243. Los perfiles Drywall Plus de Barbieri son únicos en el mercado y totalmente compatibles con los perfiles tradicionales Drywall. Su superficie moleteada mejora la telescopicidad, aumenta la rigidez a la flexión del tabique y reduce los tiempos de instalación, facilitando todo el proceso.",
        slug: "drywall-cielorraso",
        activo: true,
        isChecked: false,
      },
      {
        id: 2,
        nombre: "Drywall Tabiques Simples",
        descripcion:
          "Nueva línea de perfiles de acero galvanizado para la construcción en seco, 100% moleteados, fabricados y certificados bajo la norma IRAM IAS U 500-243. Los perfiles Drywall Plus de Barbieri son únicos en el mercado y totalmente compatibles con los perfiles tradicionales Drywall. Su superficie moleteada mejora la telescopicidad, aumenta la rigidez a la flexión del tabique y reduce los tiempos de instalación, facilitando todo el proceso.",
        slug: "drywall-pared",
        activo: true,
        isChecked: false,
      },
      {
        id: 4,
        nombre: "PVC Cielorrasos",
        descripcion:
          "Fabricamos cielos y revestimientos de PVC Perfilplas a partir de la extrusión de PVC dando como resultado un cielorraso de gran resistencia y durabilidad. Los cielos y revestimientos de PVC Perfilplas de Barbieri constituyen una ventajosa solución donde se requiera un material de fácil instalación, libre de mantenimiento, impermeable, resistente a innumerables agentes químicos, autoextinguible y de agradable aspecto estético.",
        slug: "pvc-cielorrasos",
        activo: true,
        isChecked: false,
      },
      {
        id: 5,
        nombre: "PVC Revestimiento Decorativo",
        descripcion:
          "Fabricamos cielos y revestimientos de PVC Perfilplas a partir de la extrusión de PVC dando como resultado un cielorraso de gran resistencia y durabilidad. Los cielos y revestimientos de PVC Perfilplas de Barbieri constituyen una ventajosa solución donde se requiera un material de fácil instalación, libre de mantenimiento, impermeable, resistente a innumerables agentes químicos, autoextinguible y de agradable aspecto estético.",
        slug: "pvc-revestimiento-dec",
        activo: true,
        isChecked: false,
      },
      {
        id: 8,
        nombre: "Steel Frame",
        descripcion:
          "El Steel Frame te permite realizar cualquier tipo de proyecto y ser vinculado a todo tipo de sistema constructivo. Las aislaciones hidrófugas, térmicas, acústicas y las terminaciones son resueltas con materiales industrializados y normalizados. Una de las características fundamentales del proceso constructivo es su condición de montaje en seco el cual evita la utilización de agua en toda la obra.",
        slug: "steel-frame",
        activo: true,
        isChecked: false,
      },
      {
        id: 7,
        nombre: "Suspensiones TX",
        descripcion:
          "Los perfiles Barbieri TX brindan una solución de alta performance en sistemas de soporte de cielorrasos suspendidos. Están fabricados en equipos de última tecnología europea automatizada, generando así productos confiables, de máxima precisión y alta prestación que facilitan la instalación. Tanto los largueros como los travesaños y perimetrales se fabrican estampando los agujeros y las punteras en la línea de fabricación garantizando así un encastre preciso y exacto.",
        slug: "suspensiones-tx",
        activo: true,
        isChecked: false,
      },
      {
        id: 6,
        nombre: "Zócalos PVC",
        descripcion:
          "Nuestra línea de zócalos es desarrollada a partir de PVC rígido, flexible y expandido, obteniéndose un producto de similares características que la madera, pero sin ninguno de sus inconvenientes.",
        slug: "zocalos-pvc",
        activo: true,
        isChecked: false,
      },
    ],
  });

  useEffect(() => {
    let systems = JSON.parse(sessionStorage.getItem(SISTEMAS_SELECCIONADOS));

    if (systems) {
      let breakCondition = false;
      systems.forEach((system) => {
        if (system.isChecked && !breakCondition) {
          activarSistema(system.slug);
          breakCondition = true;
        } else if (!breakCondition) {
          activarSistema("");
        }

        setState({ sistemas: systems });
        setLoading(false);
      });
    }

    setLoading(false);
  }, [setState]);

  const redirectToInput = () => {
    sessionStorage.setItem(
      SISTEMAS_SELECCIONADOS,
      JSON.stringify(state.sistemas)
    );

    if (
      state.sistemas.filter((s) => {
        return s.slug === "steel-frame" && s.isChecked;
      }).length > 0
    ) {
      sessionStorage.removeItem(STEEL_FRAME_DATA);
      sessionStorage.removeItem(DATA_INPUT_STEEL_FRAME);
      props.history.push(STEEL_FRAME_URI);
    } else {
      props.history.push(INPUT_DATA_URI);
    }
  };

  function handleCheckChieldElement(event) {
    let systems = state.sistemas;

    systems.forEach((system) => {
      if (event.target.value === "steel-frame") {
        if (system.slug === "steel-frame") {
          if (system.isChecked) {
            activarSistema("");
          } else {
            activarSistema(system.slug);
            setSeleccionados({ myArr: [] });
          }
          system.isChecked = event.target.checked;
        } else {
          if (system.isChecked) {
            system.isChecked = !event.target.checked;
          }
        }
      } else {
        //comentar esta condición para eliminnar funcionalidad de deseleccionar todos si selecciona steel-frame
        if (system.slug === "steel-frame") {
          if (system.isChecked) {
            system.isChecked = !event.target.checked;
          }
        } else {
          if (event.target.value === system.slug) {
            if (system.isChecked) {
              activarSistema("");
              removeSistemaSeleccionado(system.slug);
              setFlag(false);
            } else {
              activarSistema(system.slug);
              addSistemaSeleccionado(system.slug);
              setFlag(true);
            }
            system.isChecked = event.target.checked;
          }
        }
      }
    });

    setState({ sistemas: systems });
  }

  function removeSistemaSeleccionado(sistema) {
    var array = seleccionados.myArr;

    var index = array.indexOf(sistema);
    if (index !== -1) {
      array.splice(index, 1);
      setSeleccionados({ myArr: array });
    }

    if (array.length > 0) {
      activarSistema(array[array.length - 1]);
    } else {
      activarSistema("");
    }
  }

  function addSistemaSeleccionado(sistema) {
    setSeleccionados({ myArr: [...seleccionados.myArr, sistema] });
  }

  return (
    <div className={classes.root}>
      {messageError === "" ? (
        <div className={classes.containerResultPage}>
          <Typography className={classes.titleResultPage}>
            Calculador de Materiales
          </Typography>
          <div className={classes.divider}>
            <Divider />
          </div>
          <Grid container spacing={4} className={classes.sectionSubtitle}>
            <Grid item xs={6}>
              <Typography className={classes.subtitle}>
                Seleccione el/los sistemas de construcción a calcular
              </Typography>
            </Grid>
          </Grid>
          {loading ? (
            <LoadingPage />
          ) : (
            <Grid container spacing={4} className={classes.sectionSubtitle}>
              <Grid item xs={6}>
                <div className="checkSection">
                  <ul id="checkboxSelect">
                    {state.sistemas &&
                      state.sistemas.map((sistema, index) => {
                        return (
                          <CheckBoxComponent
                            key={index}
                            handleCheckChieldElement={handleCheckChieldElement}
                            {...sistema}
                          />
                        );
                      })}
                  </ul>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.imageReference}>
                  <div className={classes.img}>
                    {sistemaActivo === "deck" ? (
                      <div>
                        <img
                          width="100%"
                          height="auto"
                          src={require("../../assets/images/sistemas/deck.jpg")}
                          alt="Sistema Deck"
                        />
                        <Typography
                          style={{
                            color: "#022a44",
                            textAlign: "justify",
                            paddingTop: 15,
                          }}
                        >
                          {
                            state.sistemas.filter((p) => p.slug === "deck")[0]
                              .descripcion
                          }
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.img}>
                    {sistemaActivo === "drywall-pared" ? (
                      <div>
                        <img
                          width="100%"
                          height="auto"
                          src={require("../../assets/images/sistemas/pared-drywall.jpg")}
                          alt="Sistema Drywall Pared"
                        />
                        <Typography
                          style={{
                            color: "#022a44",
                            textAlign: "justify",
                            paddingTop: 15,
                          }}
                        >
                          {
                            state.sistemas.filter(
                              (p) => p.slug === "drywall-pared"
                            )[0].descripcion
                          }
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.img}>
                    {sistemaActivo === "drywall-cielorraso" ? (
                      <div>
                        <img
                          width="100%"
                          height="auto"
                          src={require("../../assets/images/sistemas/cielorraso-drywall.jpg")}
                          alt="Sistema Drywall Cielorrasos"
                        />
                        <Typography
                          style={{
                            color: "#022a44",
                            textAlign: "justify",
                            paddingTop: 15,
                          }}
                        >
                          {
                            state.sistemas.filter(
                              (p) => p.slug === "drywall-cielorraso"
                            )[0].descripcion
                          }
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.img}>
                    {sistemaActivo === "pvc-cielorrasos" ? (
                      <div>
                        <img
                          width="100%"
                          height="auto"
                          src={require("../../assets/images/sistemas/cielorraso-pvc.jpg")}
                          alt="Sistema Cielorrasos PVC"
                        />
                        <Typography
                          style={{
                            color: "#022a44",
                            textAlign: "justify",
                            paddingTop: 15,
                          }}
                        >
                          {
                            state.sistemas.filter(
                              (p) => p.slug === "pvc-cielorrasos"
                            )[0].descripcion
                          }
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.img}>
                    {sistemaActivo === "pvc-revestimiento-dec" ? (
                      <div>
                        <img
                          width="100%"
                          height="auto"
                          src={require("../../assets/images/sistemas/revestimiento-deco-pvc.jpg")}
                          alt="Sistema Revestimiento Decorativo PVC"
                        />
                        <Typography
                          style={{
                            color: "#022a44",
                            textAlign: "justify",
                            paddingTop: 15,
                          }}
                        >
                          {
                            state.sistemas.filter(
                              (p) => p.slug === "pvc-revestimiento-dec"
                            )[0].descripcion
                          }
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.img}>
                    {sistemaActivo === "suspensiones-tx" ? (
                      <div>
                        <img
                          width="100%"
                          height="auto"
                          src={require("../../assets/images/sistemas/suspensiones-tx.jpg")}
                          alt="Sistema Suspensiones TX"
                        />
                        <Typography
                          style={{
                            color: "#022a44",
                            textAlign: "justify",
                            paddingTop: 15,
                          }}
                        >
                          {
                            state.sistemas.filter(
                              (p) => p.slug === "suspensiones-tx"
                            )[0].descripcion
                          }
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.img}>
                    {sistemaActivo === "steel-frame" ? (
                      <div>
                        <img
                          width="100%"
                          height="auto"
                          src={require("../../assets/images/sistemas/steel-frame.jpg")}
                          alt="Sistema Suspensiones TX"
                        />
                        <Typography
                          style={{
                            color: "#022a44",
                            textAlign: "justify",
                            paddingTop: 15,
                          }}
                        >
                          {
                            state.sistemas.filter(
                              (p) => p.slug === "steel-frame"
                            )[0].descripcion
                          }
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={classes.img}>
                    {sistemaActivo === "zocalos-pvc" ? (
                      <div>
                        <img
                          width="100%"
                          height="auto"
                          src={require("../../assets/images/sistemas/zocalos.jpg")}
                          alt="Sistema Zócalos de PVC"
                        />
                        <Typography
                          style={{
                            color: "#022a44",
                            textAlign: "justify",
                            paddingTop: 15,
                          }}
                        >
                          {
                            state.sistemas.filter(
                              (p) => p.slug === "zocalos-pvc"
                            )[0].descripcion
                          }
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          )}

          <Grid container className={classes.containerButtons} spacing={10}>
            <Grid className={classes.buttonInput} item xs={12}>
              <ButtonSubmit
                onClick={() => redirectToInput()}
                disabled={sistemaActivo === ""}
                color="secondary"
                variant="contained"
                className={classes.button}
              >
                Continuar
              </ButtonSubmit>
            </Grid>
          </Grid>
        </div>
      ) : (
        <DescriptionAlerts
          severity="error"
          title="Error"
          message={messageError}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 5,
    overflow: "hidden",
    paddingLeft: "15%",
    paddingRight: "15%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "5%",
      paddingRight: "5%",
    },
  },
  titleResultPage: {
    fontSize: "30px",
    fontWeight: "bold",
    color: "#022A3A",
  },
  sectionSubtitle: {
    paddingTop: 20,
    paddingbottom: 20,
  },
  sectionSistemas: {
    paddingTop: 30,
    paddingbottom: 30,
  },
  subtitle: {
    fontSize: "20px",
    fontWeight: "400",
    color: "black",
  },
  containerResultPage: {
    position: "relative",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(8),
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  buttonBackInit: {
    textAlign: "end",
  },
  sectionObra: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  nombreObra: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  agrupacion: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  resultadoMaterial: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  buttonInput: {
    textAlign: "center",
  },
  containerButtons: {
    paddingTop: 50,
    paddingBottom: 50,
  },
}));

export default HomePage;

const ButtonSubmit = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "white",
    backgroundColor: "#022A3A",
    borderColor: "#022A3A",
    "&:hover": {
      backgroundColor: "#022a44",
      borderColor: "#022a44",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#022a44",
      borderColor: "#022a44",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(2,42,58,.5)",
    },
    "&:disabled": {
      backgroundColor: "white",
      borderColor: "#959994",
      boxShadow: "none",
    },
  },
})(Button);
