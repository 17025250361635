import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import HelpIcon from "@material-ui/icons/Help";
import { Divider } from "@material-ui/core";

function FormEntrepiso(props) {
  const classes = useStyles();
  const { fields, handleInputChange, tipoEntrepiso } = props;
  const [reference, setReference] = React.useState("");

  return (
    <div>
      <div>
        <Typography className={classes.textPlanta}>
            Planta 2
        </Typography>
        <Divider/>
      </div>
      <Grid container spacing={5} className={classes.gridMain}>
        <Grid className={classes.gridSectionFields} item xs={6}>
          <Grid className={classes.gridLabelFirst} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Superficie (m2){" "}
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m2"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.inputs.superficiePa}
                onChange={(e) => handleInputChange(e, "pa")}
                name="superficiePa"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step:"0.01",
                    min: 0,
                    max: 99999
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("superficiePa")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              Longitud total de paneles interiores (m){" "}
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.inputs.longitudTotalPanelInteriorPa}
                onChange={(e) => handleInputChange(e, "pa")}
                name="longitudTotalPanelInteriorPa"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: false,
                  type: "number",
                  inputProps: {
                    step:"0.01",
                    min: 0,
                    max: 99999
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("longitudTotalPanelInteriorPa")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Longitud total de
              paneles exteriores (m){" "}
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.inputs.longitudTotalPanelExteriorPa}
                onChange={(e) => handleInputChange(e, "pa")}
                name="longitudTotalPanelExteriorPa"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step:"0.01",
                    min: 0,
                    max: 99999
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("longitudTotalPanelExteriorPa")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Longitud de vanos (m){" "}
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.inputs.longitudVanosPa}
                onChange={(e) => handleInputChange(e, "pa")}
                name="longitudVanosPa"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step:"0.01",
                    min: 0,
                    max: 99999
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("longitudVanosPa")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Cantidad de vanos{" "}
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.inputs.cantidadVanosPa}
                onChange={(e) => handleInputChange(e, "pa")}
                name="cantidadVanosPa"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: false,
                  type: "number",
                  inputProps: {
                    min: 1,
                    max: 99999
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("cantidadVanosPa")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Cantidad de esquinas{" "}
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.inputs.cantidadEsquinasPa}
                onChange={(e) => handleInputChange(e, "pa")}
                name="cantidadEsquinasPa"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: false,
                  type: "number",
                  inputProps: {
                    min: 1,
                    max: 99999
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("cantidadEsquinasPa")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Altura promedio de
              paneles{" "}
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.panelExteriorPa.alturaPromedio}
                onChange={(e) => handleInputChange(e, "pa")}
                name="alturaPromedio"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step:"0.01",
                    max: 4.20,
                    min: 0,
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("alturaPromedio")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          {tipoEntrepiso !== "no-aplica" && (
            <Grid>
              <Grid className={classes.gridLabel} item xs={12}>
                <Typography className={classes.label}>
                  <span className={classes.required}>*</span> Luz de Entrepiso
                  (m)
                </Typography>
              </Grid>
              <Grid container spacing={3} className={classes.gridInputField}>
                <Grid className={classes.gridField} item xs={10}>
                  <TextField
                    placeholder="0.00 m"
                    fullWidth
                    margin="none"
                    className={classes.field}
                    value={fields.entrepiso.luzEntrepiso}
                    onChange={(e) => handleInputChange(e, "pa")}
                    name="luzEntrepiso"
                    InputLabelProps={{
                      shrink: false,
                    }}
                    InputProps={{
                      required: true,
                      type: "number",
                      inputProps: {
                        step:"0.01",
                        max: 7,
                        min: 0,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid className={classes.gridReference} item xs={2}>
                  <HelpIcon
                    onMouseOver={() => setReference("luzEntrepiso")}
                    onMouseOut={() => setReference("")}
                    className={classes.iconHelp}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid className={classes.gridSectionImages} item xs={6}>
          <div className={classes.imageReference}>
            <div className={classes.imgSup}>
              {reference === "superficiePa" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/superficiePa.jpg")}
                  alt="Superficie"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgLongExt}>
              {reference === "longitudTotalPanelExteriorPa" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/longitudTotalPanelExteriorPa.jpg")}
                  alt="Longitud Total Panel Exterior"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgLongInt}>
              {reference === "longitudTotalPanelInteriorPa" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/longitudTotalPanelInteriorPa.jpg")}
                  alt="Longitud Total Panel Interior"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgLongVan}>
              {reference === "longitudVanosPa" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/longitudVanosPa.jpg")}
                  alt="Longitud Vanos"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgCantVan}>
              {reference === "cantidadVanosPa" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/cantidadVanosPa.jpg")}
                  alt="Cantidad Vanos"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgCantEsq}>
              {reference === "cantidadEsquinasPa" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/cantidadEsquinasPa.jpg")}
                  alt="Cantidad Esquinas"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgAlturaPromedio}>
              {reference === "alturaPromedio" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/alturaPromedioPa.jpg")}
                  alt="Altura Promedio"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgLuzEntrepiso}>
              {reference === "luzEntrepiso" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/luzEntrepiso.jpg")}
                  alt="Luz Entrepiso"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default FormEntrepiso;

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: "400",
    color: "black",
    paddingBottom: 10,
  },
  required: {
    color: "red",
  },
  field: {
    paddingTop: 6,
  },
  iconHelp: {
    color: "#5B6670",
    marginTop: 20,
  },
  gridMain: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  gridInputField: {
    paddingBottom: 5,
  },
  gridLabel: {
    paddingTop: 20,
  },
  gridSectionImages: {
    padding: 20,
  },
  imageReference: {
    textAlign: "center",
  },
  imgSup: {
    position: "relative",
    paddingTop: 10,
  },
  imgLongInt: {
    position: "relative",
    paddingTop: 20,
  },
  imgLongExt: {
    position: "relative",
    paddingTop: 30,
  },
  imgLongVan: {
    position: "relative",
    paddingTop: 75,
  },
  imgCantVan: {
    position: "relative",
    paddingTop: 75,
  },
  imgCantEsq: {
    position: "relative",
    paddingTop: 75,
  },
  imgAlturaPromedio: {
    position: "relative",
    paddingTop: 75,
  },
  imgLuzEntrepiso: {
    position: "relative",
    paddingTop: 85,
  },
  iconEdit: {
    color: "#2b323c",
    marginTop: 2,
  },
  textEdit:{
    color: "#2b323c",
    fontSize: "16px",
    fontWeight: "bold",
  },
  textPlanta:{
    color: "#2b323c",
    fontSize: "22px",
    fontWeight: "bold",
  },
}));
