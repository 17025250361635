import React from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";

import HomePage from "./pages/Home/HomePage";
import InputDataPage from "./pages/InputData/InputDataPage";
import ResultadoPage from "./pages/Resultado/ResultadoPage";
import SteelFramePage from "./pages/SteelFrame/SteelFramePage";
import InputsPage from "./pages/Inputs/InputsPage";
import UserPage from "./pages/UserData/UserPage";
import ResultadoSteelFramePage from "./pages/Resultado/ResultadoSteelFramePage";
import ScrollToTop from "./components/ScrollToTop";

export const LOGIN_URI = "/login";
export const FORGOT_PASSWORD_URI = "/forgot-password";

export const HOME_URI = "/calculador";
export const STEEL_FRAME_URI = "/steel-frame";
export const INPUTS_STEEL_FRAME_URI = "/inputs-steel-frame";
export const INPUT_DATA_URI = "/input-data";
export const USER_DATA_URI = "/user-data";
export const RESULTADO_URI = "/resultado";
export const RESULTADO_STEEL_FRAME_URI = "/result-steel-frame";
export const API_BASE_URL = process.env.API_BASE_URL;

const routes = (
  <div>
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route path={HOME_URI} component={HomePage} />
          <Route path={INPUT_DATA_URI} component={InputDataPage} />
          <Route path={USER_DATA_URI} component={UserPage} />
          <Route path={RESULTADO_URI} component={ResultadoPage} />
          <Route path={STEEL_FRAME_URI} component={SteelFramePage} />
          <Route path={INPUTS_STEEL_FRAME_URI} component={InputsPage} />
          <Route
            path={RESULTADO_STEEL_FRAME_URI}
            component={ResultadoSteelFramePage}
          />
          <Route exact path="/">
            <Redirect to={HOME_URI} />
          </Route>
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  </div>
);

export default routes;
