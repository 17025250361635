import React, { useEffect } from "react";
import {
  INPUT_DATA_URI,
  RESULTADO_URI,
  RESULTADO_STEEL_FRAME_URI,
  INPUTS_STEEL_FRAME_URI,
} from "../../routes";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import getProvinciasService from "../../components/Services/getProvinciasService";
import getRubrosService from "../../components/Services/getRubrosService";
import getOcupacionesService from "../../components/Services/getOcupacionesService";
import guardarUsuarioService from "../../components/Services/guardarUsuarioService";
import LoadingPage from "../../components/LoadingPage";
import DescriptionAlerts from "../../components/DescriptionAlerts";
import FormUserData from "../../components/Forms/FormUserData";
import TerminosyCondicionesComponent from "../../components/TerminosyCondicionesComponent";
import { data } from "jquery";

export const DATA_USER = "data_user";
export const DATA_INPUT = "data_input";
export const DATA_INPUT_STEELFRAME = "data_input_steelFrame";
export const DATA_STEELFRAME = "steelFrameData";
export const SISTEMAS_SELECCIONADOS = "sistemas";
export const SISTEMAS_SELECCIONADOS_ID = "sistemas_ids";

function UserPage(props) {
  const classes = useStyles();

  const [provincias, setProvincias] = React.useState([]);
  const [ocupaciones, setOcupaciones] = React.useState([]);
  const [rubros, setRubros] = React.useState([]);
  let [loadingProvincias, setLoadingProvincias] = React.useState(true);
  let [loadingOcupaciones, setLoadingOcupaciones] = React.useState(true);
  let [loadingRubros, setLoadingRubros] = React.useState(true);
  const [messageError, setMessageError] = React.useState("");
  const [open, setOpen] = React.useState(false);
  let [loading, setLoading] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = React.useState({
    nombre: "",
    apellido: "",
    email: "",
    repetirEmail: "",
    provincia: "",
    telefono: "",
    ocupacion: "",
    rubro: "",
    compania: "",
    sitioWeb: "",
    aceptacionCondiciones: true,
    sistemaConstruccionIds: "",
    SistemaConstruccionSeleccionado: [],
    CamposSteelFrameSeleccionado: {},
    InputSteelFrameSeleccionado: {},
    SistemaConstruccionSeleccionadoId:0,
    SistemaConstruccionSteelFrameSeleccionadoId:0
  });

  const redirectToInputData = () => {
    sessionStorage.setItem(DATA_USER, JSON.stringify(formData));
    let sistemas = JSON.parse(sessionStorage.getItem("sistemas"));
    if (
      sistemas.filter(
        (sistema) =>
          sistema.isChecked === true && sistema.slug === "steel-frame"
      ).length > 0
    ) {
      props.history.push(INPUTS_STEEL_FRAME_URI);
    } else {
      props.history.push(INPUT_DATA_URI);
    }
  };

  function handleInputChange(e) {


    if (e.target.name === "nombre") {
      if (!/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g.test(e.target.value)) {
        e.target.setCustomValidity("Campo inválido");
      } else {
        e.target.setCustomValidity("");
      }
    }

    if (e.target.name === "apellido") {
      if (!/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g.test(e.target.value)) {
        e.target.setCustomValidity("Campo inválido");
      } else {
        e.target.setCustomValidity("");
      }
    }

    if (e.target.name === "compania") {
      if (e.target.value.length > 0  && !/^[a-zA-Z]+\s?[a-zA-Z]+?\s?[a-zA-Z]+?$/.test(e.target.value)) {
        e.target.setCustomValidity("Campo inválido");
      } else {
        e.target.setCustomValidity("");
      }
    }

    if (e.target.value.length > 0  && e.target.name === "sitioWeb") {
      if (/^\s/.test(e.target.value)) {
        e.target.setCustomValidity("Campo inválido");
      } else {
        e.target.setCustomValidity("");
      }
    }
    
    if (e.target.name === "repetirEmail") {
      if (e.target.value !== formData.email) {
        e.target.setCustomValidity("Los emails ingresados no coinciden.");
      } else {
        e.target.setCustomValidity("");
      }
    }

    if (e.target.name === "aceptacionCondiciones") {
      setFormData({ ...formData, [e.target.name]: e.target.checked });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    if (e.target.name === "telefono") {
      if (e.target.value.length >= 18) {
        e.target.setCustomValidity("Supero el máximo de los carácteres");
      } else {
        e.target.setCustomValidity("");
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (formData.rubro === ""){
      formData.rubro=0;
    }

    if (formData.sitioWeb === ""){
      formData.sitioWeb="-";
    }

    if (formData.compania === ""){
      formData.compania="-";
    }
    //Agrego el sistema seleccionado para enviar a HubsPot.
    //Busco en la lista de sistema seleccionados y lo filtro por id
    let sistemasSeleccionados = JSON.parse(sessionStorage.getItem(SISTEMAS_SELECCIONADOS));
    var ids = sistemasSeleccionados.filter(x=> x.isChecked).map(function(s){
      return s.id;
    });
    formData.sistemaConstruccionIds = ids;
  
    let SistemaConstruccionSeleccionado = JSON.parse(sessionStorage.getItem(DATA_INPUT));
    let InputSteelFrameSeleccionado = JSON.parse(sessionStorage.getItem(DATA_INPUT_STEELFRAME));
    let CamposSteelFrameSeleccionado = JSON.parse(sessionStorage.getItem(DATA_STEELFRAME));
    formData.SistemaConstruccionSeleccionado = SistemaConstruccionSeleccionado;
    formData.InputSteelFrameSeleccionado = InputSteelFrameSeleccionado;
    formData.CamposSteelFrameSeleccionado= CamposSteelFrameSeleccionado;

    guardarUsuarioService(formData)
      .then((response) => {
        sessionStorage.setItem(DATA_USER, JSON.stringify(formData));
        let sistemas = JSON.parse(sessionStorage.getItem("sistemas"));

        if (
          sistemas.filter(
            (sistema) =>
              sistema.isChecked === true && sistema.slug === "steel-frame"
          ).length > 0
        ) {
          InputSteelFrameSeleccionado.SistemaConstruccionSteelFrameSeleccionadoId= response.inputSteelFrameSeleccionado.id;
          sessionStorage.setItem(DATA_INPUT_STEELFRAME, JSON.stringify(InputSteelFrameSeleccionado));
          props.history.push(RESULTADO_STEEL_FRAME_URI);
          setLoading(false);
        } else {
          SistemaConstruccionSeleccionado.forEach((element) => 
            element.SistemaConstruccionSeleccionadoId= response.sistemaConstruccionSeleccionado.find((res) => res.slug == element.slug).id
          );
          sessionStorage.setItem(DATA_INPUT, JSON.stringify(SistemaConstruccionSeleccionado));
          props.history.push(RESULTADO_URI);
          setLoading(false);
        }        
      })
      .catch(function (error) {
        console.log("Error al calcular");
        setLoading(false);
        setMessageError(
          "Disculpe las molestias ocasionadas. Estamos trabajando para reestablecer la plataforma."
        );
      });
  };

  useEffect(() => {
    let inputData = JSON.parse(sessionStorage.getItem(DATA_USER));

    if (inputData) {
      setFormData(inputData);
    } else {
      setFormData({
        nombre: "",
        apellido: "",
        email: "",
        repetirEmail: "",
        provincia: "",
        telefono: "",
        ocupacion: "",
        rubro: "",
        compania: "",
        sitioWeb: "",
        aceptacionCondiciones: false,
      });
    }

    if (provincias.length === 0) {
      getProvinciasService()
        .then((response) => {
          setProvincias(response);
          setLoadingProvincias(false);
        })
        .catch(function (error) {
          console.log("Error al cargar los sistemas");
          setMessageError(
            "Disculpe las molestias ocasionadas. Estamos trabajando para reestablecer la plataforma."
          );
          setLoadingProvincias(false);
        });
    }

    if (rubros.length === 0) {
      getRubrosService()
        .then((response) => {
          setRubros(response);
          setLoadingRubros(false);
        })
        .catch(function (error) {
          console.log("Error al cargar los sistemas");
          setMessageError(
            "Disculpe las molestias ocasionadas. Estamos trabajando para reestablecer la plataforma."
          );
          setLoadingRubros(false);
        });
    }

    if (ocupaciones.length === 0) {
      getOcupacionesService()
        .then((response) => {
          setOcupaciones(response);
          setLoadingOcupaciones(false);
        })
        .catch(function (error) {
          console.log("Error al cargar los sistemas");
          setMessageError(
            "Disculpe las molestias ocasionadas. Estamos trabajando para reestablecer la plataforma."
          );
          setLoadingOcupaciones(false);
        });
    }
  }, [setProvincias, setRubros, setOcupaciones]);

  return (
    <div className={classes.root}>
      <div className={classes.containerResultPage}>
        <Typography className={classes.titleUserPage}>
          Calculador de Materiales
        </Typography>
        <div className={classes.divider}>
          <Divider />
        </div>
        <Grid container spacing={4} className={classes.sectionTitles}>
          <Grid className={classes.sectionTitle} item xs={12}>
            <Typography className={classes.title}>Confirmación</Typography>
          </Grid>
          <Grid className={classes.sectionSubtitle} item xs={12}>
            <Typography className={classes.subtitle}>
              Por favor, completá el formulario para ver el resultado
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.sectionForm}>
          {messageError === "" ? (
            <div>
              {loadingProvincias && loadingOcupaciones && loadingRubros ? (
                <LoadingPage />
              ) : (
                <form onSubmit={handleSubmit}>
                  <FormUserData
                    provincias={provincias}
                    rubros={rubros}
                    ocupaciones={ocupaciones}
                    fields={formData}
                    handleInputChange={handleInputChange}
                    handleClickOpen={handleClickOpen}
                  />
                  <Grid
                    container
                    className={classes.containerButtons}
                    spacing={10}
                  >
                    <Grid className={classes.buttonBack} item xs={12} sm={6}>
                      <ButtonBack
                        onClick={() => redirectToInputData()}
                        color="default"
                        variant="outlined"
                        className={classes.button}
                      >
                        Atrás
                      </ButtonBack>
                    </Grid>
                    <Grid className={classes.buttonInput} item xs={12} sm={6}>
                      <ButtonSubmit
                        type="submit"
                        value="Submit"
                        color="secondary"
                        variant="contained"
                        className={classes.button}
                      >
                        Calcular
                      </ButtonSubmit>
                    </Grid>
                  </Grid>
                </form>
              )}
            </div>
          ) : (
            <DescriptionAlerts
              severity="error"
              title="Error"
              message={messageError}
            />
          )}
        </Grid>
      </div>
      <TerminosyCondicionesComponent open={open} handleClose={handleClose} />
    </div>
  );
}

export default UserPage;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 5,
    overflow: "hidden",
    paddingLeft: '20%',
      paddingRight: '20%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  titleUserPage: {
    fontSize: "30px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  sectionTitles: {
    paddingTop: 20,
    paddingbottom: 20,
  },
  title: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "black",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: "400",
    color: "black",
  },
  containerResultPage: {
    position: "relative",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  buttonBackInit: {
    textAlign: "end",
  },
  sectionObra: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  nombreObra: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  agrupacion: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  resultadoMaterial: {
    paddingTop: 20,
    paddingBottom: 20,
  },

  buttonBack: {
    textAlign: "right",
    
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
    },
  },
  buttonInput: {
    textAlign: "left",
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
    },
  },
  containerButtons: {
    paddingTop: 100,
    paddingBottom: 50,
  },
  button: {
    fontSize: "20px",
    padding: "10px 90px 10px 90px",
    borderRadius: "7px",
  },
}));

const ButtonSubmit = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "white",
    backgroundColor: "#022A3A",
    borderColor: "#022A3A",
    "&:hover": {
      backgroundColor: "#022a44",
      borderColor: "#022a44",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#022a44",
      borderColor: "#022a44",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(2,42,58,.5)",
    },
  },
})(Button);

const ButtonBack = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "#5B6670",
    backgroundColor: "transparent",
    borderColor: "#5B6670",
    "&:hover": {
      backgroundColor: "rgba(91,102,112,.1)",
      borderColor: "#5B6670",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "transparent",
      borderColor: "#5B6670",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(91,102,112,.5)",
    },
  },
})(Button);
