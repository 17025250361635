import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(30),
    marginBottom: theme.spacing(30),
  },
  alertTitle:{
    color: 'black',
    fontSize: '18px'
  },
  alert:{
    color: 'black',
    fontSize: '16px',
  },
}));

export default function DescriptionAlerts(props) {
  const classes = useStyles();
  const {severity, title, message} = props;

  return (
    <div className={classes.root}>
      <Alert className={classes.alert} variant="outlined" severity={severity}>
        <AlertTitle className={classes.alertTitle}>{title}</AlertTitle>
        {message}
      </Alert>
    </div>
  );
}
