import React from "react";
import { Row, Col } from "react-bootstrap";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function FormUserData(props) {
  const classes = useStyles();

  const { fields, handleInputChange, provincias, ocupaciones, rubros, handleClickOpen } = props;

  const DesignInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "18px 26px 18px 12px",
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  return (
    <div className={classes.containerForm}>
      <Grid container spacing={5} className={classes.gridMain}>
        <Grid className={classes.gridLabel} item xs={12} sm={6}>
          <Typography className={classes.label}>
            Nombre <span className={classes.required}>*</span>
          </Typography>
          <TextField
            placeholder="Ingresar nombre"
            fullWidth
            margin="none"
            className={classes.field}
            value={fields.nombre}
            onChange={(e) => handleInputChange(e)}
            name="nombre"
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              required: true,
              type: "text",
              maxLength:"25",
              inputProps: {
                maxLength:"25"
              }
            }}
            variant="outlined"
          />
        </Grid>
        <Grid className={classes.gridLabel} item xs={12} sm={6}>
          <Typography className={classes.label}>
            Apellido <span className={classes.required}>*</span>
          </Typography>
          <TextField
            placeholder="Ingresar apellido"
            fullWidth
            margin="none"
            className={classes.field}
            value={fields.apellido}
            onChange={(e) => handleInputChange(e)}
            name="apellido"
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              required: true,
              type: "text",
              maxLength:"25",
              inputProps: {
                maxLength:"25"
              }
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container spacing={5} className={classes.gridMain}>
        <Grid className={classes.gridLabel} item xs={12} sm={6}>
          <Typography className={classes.label}>
            Email <span className={classes.required}>*</span>
          </Typography>
          <TextField
            placeholder="Ingresar email"
            fullWidth
            margin="none"
            className={classes.field}
            value={fields.email}
            onChange={(e) => handleInputChange(e)}
            name="email"
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              required: true,
              type: "email",
            }}
            variant="outlined"
          />
        </Grid>
        <Grid className={classes.gridLabel} item xs={12} sm={6}>
          <Typography className={classes.label}>
            Repetir email <span className={classes.required}>*</span>
          </Typography>
          <TextField
            placeholder="Repetir email"
            fullWidth
            margin="none"
            className={classes.field}
            value={fields.repetirEmail}
            onChange={(e) => handleInputChange(e)}
            name="repetirEmail"
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              required: true,
              type: "email",
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container spacing={5} className={classes.gridMain}>
        <Grid className={classes.gridLabel} item xs={12} sm={6}>
          <Typography className={classes.label}>
            Localidad <span className={classes.required}>*</span>
          </Typography>
          <Select
            native
            className={classes.autocomplete}
            value={fields.provincia}
            onChange={(e) => handleInputChange(e)}
            required
            input={<DesignInput />}
            fullWidth
            inputProps={{
              name: "provincia",
              id: "outlined-provincia",
            }}
          >
            <option value="">Seleccionar</option>
            {provincias &&
              provincias.map((provincia, i) => (
                <option key={i} value={provincia.id}>
                  {provincia.descripcion}
                </option>
              ))}
          </Select>
        </Grid>
        <Grid className={classes.gridLabel} item xs={12} sm={6}>
          <Typography className={classes.label}>
            Teléfono <span className={classes.required}>*</span>
          </Typography>
          <TextField
            placeholder="Teléfono"
            fullWidth
            margin="none"
            className={classes.field}
            value={fields.telefono}
            onChange={(e) => handleInputChange(e)}
            name="telefono"
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              required: true,
              type: "number",
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container spacing={5} className={classes.gridMain}>
        <Grid className={classes.gridLabel} item xs={12} sm={6}>
          <Typography className={classes.label}>
            Ocupación/Actividad <span className={classes.required}>*</span>
          </Typography>
          <Select
            native
            className={classes.autocomplete}
            value={fields.ocupacion}
            onChange={(e) => handleInputChange(e)}
            required
            input={<DesignInput />}
            fullWidth
            inputProps={{
              name: "ocupacion",
              id: "outlined-ocupacion",
            }}
          >
            <option value="">Seleccionar</option>
            {ocupaciones &&
              ocupaciones.map((ocupacion, i) => (
                <option key={i} value={ocupacion.id}>
                  {ocupacion.descripcion}
                </option>
              ))}
          </Select>
        </Grid>
        <Grid className={classes.gridLabel} item xs={12} sm={6}>
          <Typography className={classes.label}>
            Rubro/Sector <span className={classes.required}>*</span>
          </Typography>
          <Select
            native
            className={classes.autocomplete}
            value={fields.rubro}
            onChange={(e) => handleInputChange(e)}
            required
            input={<DesignInput />}
            fullWidth
            inputProps={{
              name: "rubro",
              id: "outlined-rubro",
            }}
          >
            {rubros &&
              rubros.map((rubro, i) => (
                <option key={i} value={rubro.id}>
                  {rubro.descripcion}
                </option>
              ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={5} className={classes.gridMain}>
        <Grid className={classes.gridLabel} item xs={12} sm={6}>
          <Typography className={classes.label}>
            Compañía
          </Typography>
          <TextField
            placeholder="Ingresar compañía"
            fullWidth
            margin="none"
            className={classes.field}
            value={fields.compania}
            onChange={(e) => handleInputChange(e)}
            name="compania"
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              type: "text",
            }}
            variant="outlined"
          />
        </Grid>
        <Grid className={classes.gridLabel} item xs={12} sm={6}>
          <Typography className={classes.label}>
            Sitio web
          </Typography>
          <TextField
            placeholder="Ingresar sitio web"
            fullWidth
            margin="none"
            className={classes.field}
            value={fields.sitioWeb}
            onChange={(e) => handleInputChange(e)}
            name="sitioWeb"
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              required: false,
              type: "text",
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Grid container spacing={5} className={classes.gridTerminos}>
        <Grid className={classes.gridTerminos} item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  required: true,
                }}
                checked={fields.aceptacionCondiciones}
                onChange={(e) => handleInputChange(e)}
                name="aceptacionCondiciones"
              />
            }
            label={<Typography className={classes.labelCheck}>He leído y acepto los <a href="#" style={{textDecoration:"none"}} onClick={handleClickOpen}>Términos y Condiciones</a></Typography>}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: "400",
    color: "black",
    paddingBottom: 10,
  },
  labelCheck: {
    fontSize: "18px",
    fontWeight: "400",
    color: "black",
  },
  required: {
    color: "red",
  },
  field: {
    paddingTop: 6,
  },
  iconHelp: {
    color: "#5B6670",
    marginTop: 20,
  },
  gridMain: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  gridInputField: {
    paddingBottom: 5,
  },
  gridLabel: {
    paddingTop: 20,
  },
  containerForm: {
    paddingTop: 30,
    paddingLeft: 30,
    paddingBottom: 40,
    paddingRight: 30,
  },
  gridTerminos: {
    paddingTop: 40,
  },
}));
