import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

function ReferenciaComponent(props) {
  const classes = useStyles();
  const { texto } = props;

  return (
    <div className={classes.sectionReferencia}>
      <Typography className={classes.referencia}>
        <InfoOutlinedIcon /> {texto}
      </Typography>
    </div>
  );
}

export default ReferenciaComponent;

const useStyles = makeStyles((theme) => ({
  referencia: {
    fontSize: "17px",
    fontWeight: "400",
    color: "black",
    paddingTop: 5
  },

  sectionReferencia: {
    paddingTop: 5,
    paddingBottom: 5,
  },
}));
