import axios from "axios";
import authenticateService from '../authenticate/authenticateService'

const baseURL = process.env.API_BASE_URL;
const user = 'calculadorApp';
const password = 'K5LwbVH6r9QG9TvLJbvCoZgMu3fPD0UK';


const axiosInstance = axios.create({
    baseURL: baseURL
})

axiosInstance.interceptors.request.use(
    config => {
        const credentials = { 
            usuario: user,
            password: password,
        }

        if (sessionStorage.getItem('jwtToken')){
            config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('jwtToken');
            return config;

        } else {
            const response = authenticateService(credentials)
            .then(response => {
                config.headers['Authorization'] = 'Bearer ' + response.data.jwtToken;
                sessionStorage.setItem('jwtToken', response.data.jwtToken);
                sessionStorage.setItem('refreshToken', response.data.refreshToken);
    
                return config;
            
            })
            .catch(error => {
              throw error;
            });

            return response;
        }

    },
    error => {
        Promise.reject(error)
    });


axiosInstance.interceptors.response.use((response) => {
    return response
},
    function (error) {
        const originalRequest = error.config;
        const urlRefresh = baseURL + 'usuariosbackoffice/refresh-token';
        if (error.response.status === 401 && originalRequest.url === urlRefresh) {
            return Promise.reject(error);
        }

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refresh = { 
                refreshToken: sessionStorage.getItem('refreshToken')
            }
            return axios.post(urlRefresh, refresh)
                .then(res => {
                    sessionStorage.setItem('jwtToken', res.data.jwtToken);
                    sessionStorage.setItem('refreshToken', res.data.refreshToken);

                    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.jwtToken;
                    originalRequest.headers['Authorization'] = 'Bearer ' + res.data.jwtToken;

                    return axiosInstance(originalRequest);
                })
                .catch(error => {
                    sessionStorage.removeItem('jwtToken');
                    sessionStorage.removeItem('refreshToken');
                    window.location.href = '/';
                  
                });
        }
    }
);


export default axiosInstance;