import React, { useEffect } from "react";
import "./style.module.scss";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { HOME_URI, USER_DATA_URI } from "../../routes";
import FormDeck from "../../components/Forms/FormDeck";
import getCamposRequeridosPorSistemaService from "../../components/Services/getCamposRequeridosPorSistemaService";
import getAllCamposRequeridosService from "../../components/Services/getAllCamposRequeridosService";
import LoadingPage from "../../components/LoadingPage";
import DescriptionAlerts from "../../components/DescriptionAlerts";
import FormDrywallPared from "../../components/Forms/FormDrywallPared";
import FormDrywallCielorraso from "../../components/Forms/FormDrywallCielorraso";
import FormPvcCielorraso from "../../components/Forms/FormPvcCielorraso";
import FormPvcRevestimientoDecorativo from "../../components/Forms/FormPvcRevestimientoDecorativo";
import FormSuspensionesTx from "../../components/Forms/FormSuspensionesTx";
import FormZocalo from "../../components/Forms/FormZocalo";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

export const DATA_INPUT = "data_input";
export const SISTEMAS_SELECCIONADOS = "sistemas";

export default function InputDataPage(props) {
  const classes = useStyles();
  const [sistemasSeleccionados, loadSistemas] = React.useState([]);
  let [loading, setLoading] = React.useState(true);
  const [messageError, setMessageError] = React.useState("");
  const medidas = [{ lado: "" }];
  const [inputLadoMenor, setLadoMenor] = React.useState("");
  const [inputLadoMayor, setLadoMayor] = React.useState("");

  function _renderFormContent(sistema, id_sistema, campos, index) {
    switch (sistema) {
      case "deck":
        return (
          <FormDeck
            fields={campos}
            handleInputChange={handleInputChange}
            sistema={sistema}
            idSistema={id_sistema}
            index={index}
          />
        );
      case "drywall-pared":
        return (
          <FormDrywallPared
            fields={campos}
            handleInputChange={handleInputChange}
            sistema={sistema}
            idSistema={id_sistema}
            index={index}
          />
        );
      case "drywall-cielorraso":
        return (
          <FormDrywallCielorraso
            fields={campos}
            handleInputChange={handleInputChange}
            sistema={sistema}
            idSistema={id_sistema}
            index={index}
          />
        );
      case "pvc-cielorrasos":
        return (
          <FormPvcCielorraso
            fields={campos}
            handleInputChange={handleInputChange}
            sistema={sistema}
            idSistema={id_sistema}
            index={index}
          />
        );
      case "pvc-revestimiento-dec":
        return (
          <FormPvcRevestimientoDecorativo
            fields={campos}
            handleInputChange={handleInputChange}
            sistema={sistema}
            idSistema={id_sistema}
            index={index}
          />
        );
      case "suspensiones-tx":
        return (
          <FormSuspensionesTx
            fields={campos}
            handleInputChange={handleInputChange}
            sistema={sistema}
            idSistema={id_sistema}
            index={index}
          />
        );
      case "zocalos-pvc":
        return (
          <FormZocalo
            fields={campos}
            handleInputChange={handleInputChange}
            handleInputMedidasChange={handleInputMedidasChange}
            handleAddMedidaClick={handleAddMedidaClick}
            handleRemoveMedidaClick={handleRemoveMedidaClick}
            sistema={sistema}
            idSistema={id_sistema}
            index={index}
          />
        );
      default:
        return <div>Not Found</div>;
    }
  }

  useEffect(() => {
    let sistemasSeleccionados = getSistemasSeleccionados(
      JSON.parse(sessionStorage.getItem(SISTEMAS_SELECCIONADOS))
    );

    getAllCamposRequeridosService(sistemasSeleccionados)
      .then((response) => {
        let camposReq = response;

        camposReq.map((system) => {
          let json = _armarArray(
            system.camposRequeridos,
            system.slug,
            system.nombre,
            1
          );
          system.camposRequeridos = [];
          system.camposRequeridos[0] = JSON.parse(json);
        });
        let inputData = JSON.parse(sessionStorage.getItem(DATA_INPUT));

        if (inputData && inputData.length > 0) {
          let aux = [];

          let data_aux = inputData.filter(
            (sist) =>
              sist.slug === "drywall-cielorraso" ||
              sist.slug === "pvc-cielorrasos"
          );

          data_aux.map((data) => {
            data.camposRequeridos.map((r) => {
              if (
                parseFloat(r.ladoMayorAmbiente) <
                parseFloat(r.ladoMenorAmbiente)
              ) {
                r.ladoMenorAmbiente = "";
                r.ladoMayorAmbiente = "";
              }
            });
          });

          camposReq.map((x) => {
            if (inputData.filter((i) => i.id === x.id).length > 0) {
              aux.push(inputData.filter((i) => i.id === x.id)[0]);
            } else {
              aux.push(x);
            }
          });
          loadSistemas(aux);
          setLoading(false);
        } else {
          loadSistemas(camposReq);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setMessageError(
          "Disculpe las molestias ocasionadas. Estamos trabajando para reestablecer la plataforma."
        );
        setLoading(false);
      });
  }, [loadSistemas]);

  function getSistemasSeleccionados(sistemas) {
    let seleccionados = [];
    sistemas
      .filter((sistema) => sistema.isChecked === true)
      .map((seleccionado) => {
        seleccionados.push(seleccionado.id);
      });
    return seleccionados;
  }

  function _getCamposRequeridos(id, slug, index, name) {
    const data = getCamposRequeridosPorSistemaService(id)
      .then((response) => {
        let camposReq = [];
        camposReq[0] = response;

        addCampos(
          id,
          index,
          JSON.parse(
            _armarArray(camposReq[0].camposRequeridos, slug, name, index + 2)
          )
        );
      })
      .catch(function (error) {
        setMessageError(
          "Disculpe las sssmolestias ocasionadas. Estamos trabajando para reestablecer la plataforma."
        );
        console.log("Error al cargar los sistemas");
      });

    return data;
  }

  function _armarArray(campos, slug, name, index) {
    let aux = "{";

    if (slug === "suspensiones-tx") {
      aux +=
        '"nombre":"Perfiles TX para Cielorrasos Suspendidos ' + index + '",';
    } else if (slug === "drywall-pared") {
      aux += '"nombre":"Drywall Tabique Simple ' + index + '",';
    } else if (slug === "pvc-cielorrasos") {
      aux += '"nombre":"PVC Cielorraso ' + index + '",';
    } else {
      aux += '"nombre":"' + name + " " + index + '",';
    }

    campos.map((campo, index) => {
      if (
        (slug === "deck" ||
          slug === "drywall-pared" ||
          slug === "drywall-cielorraso" ||
          slug === "pvc-cielorrasos" ||
          slug === "pvc-revestimiento-dec" ||
          slug === "suspensiones-tx" ||
          slug === "zocalos-pvc") &&
        campo.nombre === "desperdicio"
      ) {
        aux += '"' + campo.nombre + '":"5"';
      } else if (slug === "drywall-pared" && campo.nombre === "modulacion") {
        aux += '"' + campo.nombre + '":"0.40"';
      } else if (slug === "pvc-cielorrasos" && campo.nombre === "modulacion") {
        aux += '"' + campo.nombre + '":"0.50"';
      } else if (
        slug === "drywall-cielorraso" &&
        campo.nombre === "modulacion"
      ) {
        aux += '"' + campo.nombre + '":"0.40"';
      } else if (slug === "zocalos-pvc" && campo.nombre === "medidas") {
        aux += '"' + campo.nombre + '":[{' + '"lado":""' + "}]";
      } else {
        aux += '"' + campo.nombre + '":""';
      }

      if (index < campos.length - 1) aux += ",";
    });
    aux += "}";

    return aux;
  }

  const handleAddClick = (sistema, slugSistema, index, name) => {
    _getCamposRequeridos(sistema, slugSistema, index, name);
  };

  const handleAddMedidaClick = (sistema, indexSistema, indexMedidas) => {
    let systems = [...sistemasSeleccionados];
    const campoAdd = { lado: "" };

    systems.map((system) => {
      if (system.slug === sistema) {
        const fields = [...system.camposRequeridos];

        let auxiliar = fields[indexSistema]["medidas"];
        auxiliar[indexMedidas + 1] = campoAdd;
        fields[indexSistema]["medidas"] = auxiliar;
        [...system.camposRequeridos] = fields;
      }
    });

    loadSistemas(systems);
  };

  const handleRemoveMedidaClick = (sistema, indexSistema, indexMedidas) => {
    let systems = [...sistemasSeleccionados];
    const campoAdd = { lado: "" };

    systems.map((system) => {
      if (system.slug === sistema) {
        const fields = [...system.camposRequeridos];

        let auxiliar = fields[indexSistema]["medidas"];
        auxiliar.splice(indexMedidas, 1);

        fields[indexSistema]["medidas"] = auxiliar;
        [...system.camposRequeridos] = fields;
      }
    });

    loadSistemas(systems);
  };

  const handleInputMedidasChange = (e, sistema, index, indexMedida) => {
    let systems = [...sistemasSeleccionados];
    const { name, value } = e.target;

    systems.map((system) => {
      if (system.slug === sistema) {
        const fields = [...system.camposRequeridos];
        let auxiliar = fields[index]["medidas"];
        auxiliar[indexMedida][name] = value;

        fields[index]["medidas"] = auxiliar;
        [...system.camposRequeridos] = fields;
      }
    });

    loadSistemas(systems);
  };

  const addCampos = (sistema, index, cadena) => {
    let systems = [...sistemasSeleccionados];

    systems.map((system) => {
      if (system.id === sistema) {
        system.camposRequeridos[index + 1] = cadena;
      }
    });

    loadSistemas(systems);
  };

  const handleRemoveClick = (sistema, index) => {
    let systems = [...sistemasSeleccionados];

    systems.map((system) => {
      if (system.slug === sistema) {
        const fields = [...system.camposRequeridos];
        fields.splice(index, 1);
        [...system.camposRequeridos] = fields;
      }
    });

    loadSistemas(systems);
  };

  const handleInputChange = (e, sistema, index) => {
    let systems = [...sistemasSeleccionados];
    const { name, value } = e.target;

    if (e.target.name === "nombre") {
      if (/^\s/.test(e.target.value)) {
        e.target.setCustomValidity("Debe escribir un título válido");
      } else {
        e.target.setCustomValidity("");
      }
    }

    if (sistema === "pvc-cielorrasos" || sistema === "drywall-cielorraso") {
      if (e.target.name === "ladoMenorAmbiente") {
        setLadoMenor(e.target);
        sistemasSeleccionados
          .filter((sis) => sis.slug === sistema)
          .map((seleccionado) => {
            if (
              seleccionado.camposRequeridos[index].ladoMayorAmbiente &&
              parseFloat(e.target.value) >
                parseFloat(
                  seleccionado.camposRequeridos[index].ladoMayorAmbiente
                )
            ) {
              e.target.setCustomValidity(
                "El lado menor del ambiente no puede ser superior al lado mayor"
              );
            } else {
              e.target.setCustomValidity("");
              if (inputLadoMayor !== "") {
                inputLadoMayor.setCustomValidity("");
              }
            }
          });
      }

      if (e.target.name === "ladoMayorAmbiente") {
        setLadoMayor(e.target);
        sistemasSeleccionados
          .filter((sis) => sis.slug === sistema)
          .map((seleccionado) => {
            if (
              seleccionado.camposRequeridos[index].ladoMenorAmbiente &&
              parseFloat(e.target.value) <
                parseFloat(
                  seleccionado.camposRequeridos[index].ladoMenorAmbiente
                )
            ) {
              e.target.setCustomValidity(
                "El lado mayor del ambiente no puede ser inferior al lado menor"
              );
            } else {
              e.target.setCustomValidity("");
              if (inputLadoMenor !== "") {
                inputLadoMenor.setCustomValidity("");
              }
            }
          });
      }
    }

    systems.map((system) => {
      if (system.slug === sistema) {
        const fields = [...system.camposRequeridos];
        fields[index][name] = value;
        [...system.camposRequeridos] = fields;
      }
    });

    loadSistemas(systems);
  };

  const redirectToHome = () => {
    sessionStorage.setItem(DATA_INPUT, JSON.stringify(sistemasSeleccionados));
    props.history.push(HOME_URI);
  };

  function handleSubmit(event) {
    event.preventDefault();
    sessionStorage.setItem(DATA_INPUT, JSON.stringify(sistemasSeleccionados));
    props.history.push(USER_DATA_URI);
  }

  return (
    <div className={classes.root}>
      {messageError === "" ? (
        <div className={classes.containerInputPage}>
          <Typography className={classes.titleInputPage}>
            Calculador de Materiales
          </Typography>
          <div className={classes.divider}>
            <Divider />
          </div>
          {loading ? (
            <LoadingPage />
          ) : (
            <form onSubmit={handleSubmit}>
              {sistemasSeleccionados &&
                sistemasSeleccionados.map((sistema, index) => {
                  return (
                    <div key={index} className={classes.sectionInputsPage}>
                      {sistema.slug === "suspensiones-tx" ? (
                        <Typography className={classes.subtitleInputPage}>
                          Sistema Perfiles TX para Cielorrasos Suspendidos
                        </Typography>
                      ) : (
                        <Typography className={classes.subtitleInputPage}>
                          Sistema {sistema.nombre}
                        </Typography>
                      )}
                      <div className={classes.rowForm}>
                        {sistema &&
                          sistema.camposRequeridos.map((campo, index) => {
                            return (
                              <div key={index} className={classes.formSystem}>
                                <div className={classes.dividers}>
                                  {index !== 0 && <Divider />}
                                </div>
                                {_renderFormContent(
                                  sistema.slug,
                                  sistema.id,
                                  campo,
                                  index
                                )}
                                <Grid
                                  container
                                  className={classes.buttonsAction}
                                  spacing={3}
                                >
                                  {sistema &&
                                    sistema.camposRequeridos.length !== 1 && (
                                      <div className={classes.buttonMinus}>
                                        <Typography className={classes.textAdd}>
                                          <Fab
                                            size="small"
                                            aria-label="remove"
                                            onClick={() =>
                                              handleRemoveClick(
                                                sistema.slug,
                                                index
                                              )
                                            }
                                            className={classes.fabRemove}
                                          >
                                            <RemoveIcon />
                                          </Fab>{" "}
                                          Eliminar
                                        </Typography>
                                      </div>
                                    )}
                                  {sistema &&
                                    sistema.camposRequeridos.length - 1 ===
                                      index && (
                                      <div className={classes.buttonAdd}>
                                        <Typography className={classes.textAdd}>
                                          <Fab
                                            size="small"
                                            aria-label="add"
                                            onClick={() =>
                                              handleAddClick(
                                                sistema.id,
                                                sistema.slug,
                                                index,
                                                sistema.nombre
                                              )
                                            }
                                            className={classes.fabAdd}
                                          >
                                            <AddIcon />
                                          </Fab>{" "}
                                           ¿Necesitás agregar más?
                                        </Typography>
                                      </div>
                                    )}
                                </Grid>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}

              <Grid container className={classes.containerButtons} spacing={10}>
                <Grid className={classes.buttonBack} item xs={6}>
                  <ButtonBack
                    onClick={() => redirectToHome()}
                    color="default"
                    variant="outlined"
                    className={classes.button}
                  >
                    Atrás
                  </ButtonBack>
                </Grid>
                <Grid className={classes.buttonInput} item xs={6}>
                  <ButtonSubmit
                    type="submit"
                    value="Submit"
                    color="secondary"
                    variant="contained"
                    className={classes.button}
                  >
                    Continuar
                  </ButtonSubmit>
                </Grid>
              </Grid>
            </form>
          )}
        </div>
      ) : (
        <DescriptionAlerts
          severity="error"
          title="Error"
          message={messageError}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 5,
    overflow: "hidden",
    paddingLeft: '15%',
    paddingRight: '15%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '3%',
      paddingRight: '3%',
    },
  },
  formSystem: {
    padding: theme.spacing(3, 3),
  },
  fabAdd: {
    backgroundColor: theme.palette.yellow.main,
  },
  fabRemove: {
    backgroundColor: theme.palette.pantone.light,
  },
  titleInputPage: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#022A3A",
  },
  buttonMinus: {
    paddingRight: 10,
  },
  subtitleInputPage: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#022A3A",
  },
  textAdd: {
    fontSize: "16px",
    fontWeight: "400",
    color: "black",
  },
  containerInputPage: {
    position: "relative",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(8),
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  dividers: {
    paddingTop: 10,
    paddingBottom: 30,
  },
  sectionInputsPage: {
    paddingTop: 20,
    paddingBottom: 30,
  },
  formEntrepiso: {
    paddingTop: 15,
  },
  formEntrepisoTanque: {
    paddingTop: 15,
  },
  buttonsAction: {
    paddingTop: 20,
    paddingLeft: 10
  },

  buttonBack: {
    textAlign: "right",
  },
  buttonInput: {
    textAlign: "left",
  },
  containerButtons: {
    paddingTop: 100,
    paddingBottom: 50,
  },
}));

const ButtonSubmit = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "white",
    backgroundColor: "#022A3A",
    borderColor: "#022A3A",
    "&:hover": {
      backgroundColor: "#022a44",
      borderColor: "#022a44",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#022a44",
      borderColor: "#022a44",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(2,42,58,.5)",
    },
  },
})(Button);

const ButtonBack = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "#5B6670",
    backgroundColor: "transparent",
    borderColor: "#5B6670",
    "&:hover": {
      backgroundColor: "rgba(91,102,112,.1)",
      borderColor: "#5B6670",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "transparent",
      borderColor: "#5B6670",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(91,102,112,.5)",
    },
  },
})(Button);
