import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILTURE, LOGOUT } from "../actions/loginActionTypes";

const initialState = {
  hasError: false,
  username: "test",
  password: "test",
  isFetching: false,
  isLoggedIn: false,
  authentication: undefined
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      const { username, password } = action.payload;
      return {
        ...state,
        isFetching: true,
        isLoggedIn: false,
        username,
        password
      };
    }
    case LOGIN_SUCCESS: {
      const isLoggedIn = (action && action.payload && !action.payload.hasOwnProperty("error"));
      return {
        ...state,
        isFetching: false,
        isLoggedIn: isLoggedIn,
        authentication: action.payload,
        password: undefined
      };
    }
    case LOGIN_FAILTURE: {
      return { 
        ...state, 
        isFetching: false,
        isLoggedIn: false,
        authentication: action.payload,
        password: undefined
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isFetching: false,
        isLoggedIn: false,
        authentication: undefined,
        password: false
      }
    }
    default: {
      return state;
    }
  }
};

export default loginReducer;

export const getLoginReducer = (state) => state.loginReducer;

export const getIsLoggedIn = (state) => getLoginReducer(state).isLoggedIn;

export const getIsFetching = (state) => getLoginReducer(state).isFetching;

export const getUsername = (state) => getLoginReducer(state).username;

export const getUserDisplayName = (state) => {
  const splitChar = "\\";

  let username = getLoginReducer(state).username;
  if(username.indexOf(splitChar) === -1){
    return username;
  }

  return username.split(splitChar)[1];
};