import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";

function CategoriaAgrupadaResultadoComponent(props) {
  const classes = useStyles();
  const {
    name,
    nameA1, 
    cantidadA1,
    unidadA1,
    nameA2,
    cantidadA2,
    unidadA2,
    total,
    totalUnidad,
    esComerciable
  } = props;

  return (
    <div className={classes.containerMaterial}>
      <Grid container style={{paddingBottom: 10}} spacing={4}>
        <Hidden mdDown>
          <Grid item xs={2} md={2} only="md"></Grid>
        </Hidden>
        <Grid item className={classes.name} xs={6} md={4}>
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            borderBottom={1}
          >
            <Typography className={classes.textName}>{name}</Typography>
          </Box>
        </Grid>
        <Grid item className={classes.name} xs={6} md={4}></Grid>
        <Hidden mdDown>
          <Grid item xs={2} md={2} only="md"></Grid>
        </Hidden>
      </Grid>

      <Grid container style={{paddingTop: 10}} spacing={4}>
        <Hidden mdDown>
          <Grid item xs={2} md={2} only="md"></Grid>
        </Hidden>

        <Grid item className={classes.cantidades} xs={6} md={4}>
          <Grid container spacing={4}>
            <Grid item className={classes.sectionCantidad} xs={6} md={8}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                borderBottom={0}
              >
                <Typography className={classes.textName}>{nameA1} <span className={classes.esComerciable}>{!esComerciable ? '(*)' : ''}</span></Typography>
              </Box>
            </Grid>
            <Grid item className={classes.sectionCantidad} xs={6} md={4}>
              <Box className={classes.boxName} borderLeft={1}>
                <Typography className={classes.textCantidad}>
                  {cantidadA1}
                </Typography>
                <Typography className={classes.textCantidad}>
                  {unidadA1}
                </Typography>
              </Box>
            </Grid>
            <Grid item className={classes.sectionCantidad} xs={6} md={8}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="left"
                borderBottom={0}
              >
                <Typography className={classes.textName}>{nameA2} <span className={classes.esComerciable}>{!esComerciable ? '(*)' : ''}</span></Typography>
              </Box>
            </Grid>
            <Grid item className={classes.sectionCantidad} xs={6} md={4}>
              <Box className={classes.boxName} borderLeft={1}>
                <Typography className={classes.textCantidad}>
                  {cantidadA2}
                </Typography>
                <Typography className={classes.textCantidad}>
                  {unidadA2}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.icon} xs={1} md={2}>
          <Box
          display="flex"
            justifyContent="center"
            alignItems="center"
            borderRight={0}
            className={classes.boxIcon}
          >
            <ArrowForwardIosIcon className={classes.icon} />
          </Box>
        </Grid>
        <Grid item className={classes.totales} xs={5} md={2}>
          <Grid container spacing={4}>
            <Grid item className={classes.sectionOtraCantidad} xs={12} md={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRight={0}
              >
                <Typography className={classes.textName}>Total</Typography>
              </Box>
            </Grid>
            <Grid item className={classes.sectionOtraCantidad} xs={12} md={12}>
              <Box
                borderRight={1}
                borderLeft={4}
                borderTop={1}
                borderBottom={1}
                borderRadius={5}
                className={classes.boxOtraCantidad}
              >
                <Typography className={classes.textOtraCantidad}>
                  {total}
                </Typography>
                <Typography className={classes.textOtraUnidad}>
                  {totalUnidad}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Hidden mdDown>
          <Grid item xs={1} md={1} only="md"></Grid>
        </Hidden>
      </Grid>
    </div>
  );
}

export default CategoriaAgrupadaResultadoComponent;

const useStyles = makeStyles((theme) => ({
  textName: {
    fontSize: "21px",
    fontWeight: "900",
    color: "black",
    textAlign: "left"
  },
  esComerciable: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#E2231A",
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  sectionCantidad: {
    textAlign: "center",
  },
  boxName: {
    textAlign: "center",
    paddingLeft: 30,
  },
  textCantidad: {
    fontSize: "21px",
    fontWeight: "500",
    color: "black",
    textAlign: "left",
  },
  icon: {
    color: "#979797",
  },
  boxIcon: {
    paddingTop: 60
  },
  textOtraCantidad: {
    fontSize: "19px",
    fontWeight: "900",
    color: "black",
    textAlign: "left",
  },
  textOtraUnidad: {
    fontSize: "20px",
    fontWeight: "400",
    color: "black",
    textAlign: "left",
  },
  boxOtraCantidad: {
    borderColor: "#FDE300",
    paddingLeft: 15,
    paddingTop: 5,
    paddingBottom: 5,
  },
  containerMaterial: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));
