import React, { useEffect } from "react";
import InputBase from "@material-ui/core/InputBase";
import Select from "@material-ui/core/Select";
import getCampoOfSystem from "../Services/getCampoOfSystem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import HelpIcon from "@material-ui/icons/Help";
import EditIcon from '@material-ui/icons/Edit';

export default function FormPvcCielorraso(props) {
  const classes = useStyles();

  const { fields, handleInputChange, sistema, idSistema, index } = props;
  const [modulaciones, setModulacion] = React.useState([]);
  const [colores, setColores] = React.useState([]);
  const [largoTablas, setLargoTablas] = React.useState([]);
  const [reference, setReference] = React.useState("");

  useEffect(() => {
    if (modulaciones.length === 0) {
      getCampoOfSystem(4, "modulacion")
        .then((response) => {
          setModulacion(response);
        })
        .catch(function (error) {
          console.log("Error al cargar los valores de modulacion");
        });
    }
    if (colores.length === 0) {
      getCampoOfSystem(4, "color")
        .then((response) => {
          setColores(response);
        })
        .catch(function (error) {
          console.log("Error al cargar los valores de colores");
        });
    }
    if (largoTablas.length === 0) {
      getCampoOfSystem(4, "largo-tabla-pvc")
        .then((response) => {
          setLargoTablas(response);
        })
        .catch(function (error) {
          console.log("Error al cargar tabla de largo tabla pvc");
        });
    }
  }, [setModulacion, setColores, setLargoTablas]);

  return (
    <div>
      <div className="row">
        <div className="column icon">
        <EditIcon className={classes.iconEdit}/>
        </div>
        <div className="column name-calculo">
          <input
            type="text"
            required
            name="nombre"
            maxLength="31"
            value={fields.nombre}
            onChange={(e) => handleInputChange(e, sistema, index)}
          />
          <Typography className={classes.textEdit}>
            Puede editar el nombre del cálculo
          </Typography>
        </div>
        
      </div>
      <Grid container spacing={5} className={classes.gridMain}>
        <Grid className={classes.gridSectionFields} item xs={6}>
          <Grid className={classes.gridLabelFirst} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Lado menor ambiente
              (m)
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.ladoMenorAmbiente}
                onChange={(e) => handleInputChange(e, sistema, index)}
                name="ladoMenorAmbiente"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step: "0.01",
                    min: "0",
                    max: "9999",
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("lado-menor")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Lado mayor ambiente
              (m)
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.ladoMayorAmbiente}
                onChange={(e) => handleInputChange(e, sistema, index)}
                name="ladoMayorAmbiente"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step: "0.01",
                    min: "0",
                    max: "9999",
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("lado-mayor")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Altura de velas (m)
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.alturaVelas}
                onChange={(e) => handleInputChange(e, sistema, index)}
                name="alturaVelas"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step: "0.01",
                    min: "0",
                    max: "9999",
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("altura-velas")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Modulación (m)
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <Select
                native
                className={classes.autocomplete}
                value={fields.modulacion}
                onChange={(e) => handleInputChange(e, sistema, index)}
                required
                input={<DesignInput />}
                fullWidth
                inputProps={{
                  name: "modulacion",
                  id: "outlined-modulacion",
                }}
              >
                <option value="">Seleccionar</option>
                {modulaciones &&
                  modulaciones.map((modulacion, i) => (
                    <option key={i} value={modulacion.valor}>
                      {modulacion.valor}
                    </option>
                  ))}
              </Select>
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("modulacion")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Largos tablas de PVC
              (m)
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <Select
                native
                className={classes.autocomplete}
                value={fields.largoTablasPvc}
                onChange={(e) => handleInputChange(e, sistema, index)}
                required
                input={<DesignInput />}
                fullWidth
                inputProps={{
                  name: "largoTablasPvc",
                  id: "outlined-largo-tablas",
                }}
              >
                <option value="">Seleccionar</option>
                {largoTablas &&
                  largoTablas.map((largo, i) => (
                    <option key={i} value={largo.valor}>
                      {largo.valor}
                    </option>
                  ))}
              </Select>
            </Grid>

            <Grid className={classes.gridReference} item xs={2}></Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              Desperdicio sugerido 5%
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.desperdicio}
                onChange={(e) => handleInputChange(e, sistema, index)}
                name="desperdicio"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    min: "0",
                    max: "100",
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}></Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Color
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              {fields.largoTablasPvc === "6" ? (
                <Select
                  native
                  className={classes.autocomplete}
                  value={fields.color}
                  onChange={(e) => handleInputChange(e, sistema, index)}
                  required
                  input={<DesignInput />}
                  fullWidth
                  inputProps={{
                    name: "color",
                    id: "outlined-colores",
                  }}
                >
                  <option value="">Seleccionar</option>
                  {colores &&
                    colores.map((color, i) => (
                      <option key={i} value={color.id}>
                        {color.valor}
                      </option>
                    ))}
                </Select>
              ) : (
                <Select
                  native
                  className={classes.autocomplete}
                  value={fields.color}
                  onChange={(e) => handleInputChange(e, sistema, index)}
                  required
                  input={<DesignInput />}
                  fullWidth
                  inputProps={{
                    name: "color",
                    id: "outlined-color",
                  }}
                >
                  <option value="">Seleccionar</option>
                  <option value="9">Blanco</option>
                </Select>
              )}
              <Typography className={classes.textColorRefer}>Colores Arrayán, Cedro y Haya se comercializan solo en 6m de largo</Typography>
            </Grid>

            <Grid className={classes.gridReference} item xs={2}></Grid>
          </Grid>
        </Grid>

        <Grid className={classes.gridSectionImages} item xs={6}>
          <div className={classes.imageReference}>
            <div className={classes.imgLadoMenor}>
              {reference === "lado-menor" ? (
                <img
                  width="80%"
                  height="auto"
                  src={require("../../assets/images/drywall-cielorraso/lado-menor.jpg")}
                  alt="Lado menor PVC cielorraso"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgLadoMayor}>
              {reference === "lado-mayor" ? (
                <img
                  width="80%"
                  height="auto"
                  src={require("../../assets/images/pvc-cielorraso/lado-mayor.jpg")}
                  alt="Lado mayor PVC cielorraso"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgAlturaVelas}>
              {reference === "altura-velas" ? (
                <img
                  width="80%"
                  height="auto"
                  src={require("../../assets/images/pvc-cielorraso/altura-velas.jpg")}
                  alt="Altura de velas PVC cielorraso"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgModulacion}>
              {reference === "modulacion" ? (
                <img
                  width="80%"
                  height="auto"
                  src={require("../../assets/images/pvc-cielorraso/modulacion.jpg")}
                  alt="Modulación PVC cielorraso"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: "400",
    color: "black",
    paddingBottom: 10,
  },
  iconEdit: {
    color: "#2b323c",
    marginTop: 2,
  },
  textEdit:{
    color: "#2b323c",
    fontSize: "16px",
    fontWeight: "bold",
  },
  referencia: {
    fontSize: "16px",
    fontWeight: "400",
    color: "black",
  },
  textColorRefer: {
    fontSize: "13px",
    fontWeight: "400",
    color: "black",
    fontStyle: 'italic',
    paddingTop: 5
  },
  required: {
    color: "red",
  },
  field: {
    paddingTop: 6,
  },
  iconHelp: {
    color: "#5B6670",
    marginTop: 20,
  },
  gridMain: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  gridReferencia: {
    paddingTop: 30,
  },
  gridInputField: {
    paddingBottom: 5,
  },
  gridLabel: {
    paddingTop: 20,
  },
  gridSectionImages: {
    padding: 20,
  },
  imageReference: {
    textAlign: "center",
  },
  imgLadoMenor: {
    position: "relative",
    paddingTop: 10,
  },
  imgLadoMayor: {
    position: "relative",
    paddingTop: 10,
  },
  imgAlturaVelas: {
    position: "relative",
    paddingTop: 10,
  },
  imgModulacion: {
    position: "relative",
    paddingTop: 10,
  },
}));

const DesignInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "18px 26px 18px 12px",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
