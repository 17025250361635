import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormPlantaBaja from "../SteelFrame/Forms/FormPlantaBaja";
import Grid from "@material-ui/core/Grid";
import FormEntrepiso from "../SteelFrame/Forms/FormEntrepiso";
import FormEntrepisoTanque from "../SteelFrame/Forms/FormEntrepisoTanque";
import precalcularService from "../../components/Services/precalcularService";
import { STEEL_FRAME_URI, USER_DATA_URI } from "../../routes";
import AvisoCalculoSteelFrameModal from "../../components/AvisoCalculoSteelFrameModal"

export const STEEL_FRAME_DATA = "steelFrameData";

export const DATA_INPUT_STEEL_FRAME = "data_input_steelFrame";

function InputsPage(props) {
  const classes = useStyles();
  const [autompleteForm, autocompletarForm] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [aviso, setAviso] = React.useState("");
  const [formDataPB, setFormDataPb] = React.useState({
    nombre: "Nombre del proyecto",
    inputs: {
      superficiePb: "",
      longitudTotalPanelInteriorPb: "0",
      longitudTotalPanelExteriorPb: "",
      longitudVanosPb: "",
      cantidadVanosPb: "",
      cantidadEsquinasPb: "",
    },
    panelExteriorPb: { alturaPromedio: "" },
    cubierta: { luzCubierta: "" },
  });

  const [steelFrameData, setSteelFrameData] = React.useState(
    JSON.parse(sessionStorage.getItem(STEEL_FRAME_DATA))
  );

  const [formDataEntrepiso, setFormDataEntrepiso] = React.useState({
    nombre: "Planta 2",
    inputs: {
      superficiePa: "",
      longitudTotalPanelInteriorPa: "0",
      longitudTotalPanelExteriorPa: "",
      longitudVanosPa: "",
      cantidadVanosPa: "",
      cantidadEsquinasPa: "",
    },
    panelExteriorPa: { alturaPromedio: "" },
    entrepiso: { luzEntrepiso: "" },
  });

  const [formDataEntrepisoTanque, setFormDataEntrepisoTanque] = React.useState({
    nombre: "Tanque reserva",
    inputs: {
      superficieEntrepisoTanqueReserva: "",
      longitudPanelesTanqueReserva: "",
      alturaPanelesTanqueReserva: "",
      capacidadTanqueReserva: "",
      diametroTanqueReserva: "",
    },
    entrepisoTanque: { luzEntrepisoTanque: "" },
  });

  useEffect(() => {
    let inputs = JSON.parse(sessionStorage.getItem(DATA_INPUT_STEEL_FRAME));

    if (inputs && !autompleteForm) {

      if (steelFrameData.tipoCubierta !== 'no-aplica' && !inputs.steelframe.plantaBaja.cubierta){
        inputs.steelframe.plantaBaja.cubierta={ luzCubierta: "" };
        console.log(inputs.steelframe.plantaBaja)
      }

      setFormDataPb(inputs.steelframe.plantaBaja);

      if (
        steelFrameData.cantidadPlantas === "2" &&
        inputs.steelframe.plantaAlta &&
        inputs.steelframe.plantaAlta.inputs.superficiePa
      ) {
        if (steelFrameData.tipoEntrepiso !== 'no-aplica' && !inputs.steelframe.plantaAlta.entrepiso){
          inputs.steelframe.plantaAlta.entrepiso ={ luzEntrepiso: "" };
        }
      console.log(inputs.steelframe.plantaAlta)
        setFormDataEntrepiso(inputs.steelframe.plantaAlta);
      }

      if (
        steelFrameData.calculaTanque === "1" &&
        inputs.steelframe.tanqueReserva
      ) {
        setFormDataEntrepisoTanque(inputs.steelframe.tanqueReserva);
      }

      autocompletarForm(true);
    }
  });

  const redirectBack = () => {
    let formPbAux = formDataPB;
    let formEntrepisoAux = null;
    let formEntrepisoTanqueAux = null;

    if (steelFrameData.tipoCubierta !== "no-aplica") {
      formPbAux.cubierta = {
        tipoCubierta: steelFrameData.tipoCubierta,
        luzCubierta: formDataPB.cubierta.luzCubierta,
      };
    } else {
      formPbAux.cubierta = null;
    }

    if (steelFrameData.cantidadPlantas === "2") {
      formEntrepisoAux = formDataEntrepiso;

      if (steelFrameData.tipoEntrepiso !== "no-aplica") {
        formEntrepisoAux.entrepiso = {
          tipoEntrepiso: steelFrameData.tipoEntrepiso,
          luzEntrepiso: formDataEntrepiso.entrepiso.luzEntrepiso,
        };
      } else {
        formEntrepisoAux.entrepiso = null;
      }
    }

    if (steelFrameData.calculaTanque === "1") {
      formEntrepisoTanqueAux = formDataEntrepisoTanque;
      formEntrepisoTanqueAux.entrepisoTanque = {
        entrepisoTanque: steelFrameData.tipoApoyoTanque,
        luzEntrepisoTanque:
          formDataEntrepisoTanque.entrepisoTanque.luzEntrepisoTanque,
      };
    }

    let dataSteelFrame = {
      id: 8,
      nombre: "Steel Frame",
      slug: "steel-frame",
      inputs: [],
      steelframe: {
        zonaGeografica: steelFrameData.zonaGeografica,
        exposicionViento: steelFrameData.exposicionViento,
        plantaBaja: formPbAux,
        plantaAlta: formEntrepisoAux,
        tanqueReserva: formEntrepisoTanqueAux,
      },
    };

    sessionStorage.setItem(
      DATA_INPUT_STEEL_FRAME,
      JSON.stringify(dataSteelFrame)
    );
    props.history.push(STEEL_FRAME_URI);
  };

  function handleInputChange(e, planta) {
    if (planta === "pb") {
      if (e.target.name === "alturaPromedio") {
        setFormDataPb({
          ...formDataPB,
          ["panelExteriorPb"]: {
            ...formDataPB.panelExteriorPb,
            [e.target.name]: e.target.value,
          },
        });
      } else if (e.target.name === "luzCubierta") {
        setFormDataPb({
          ...formDataPB,
          ["cubierta"]: {
            ...formDataPB.cubierta,
            [e.target.name]: e.target.value,
          },
        });
      } else if (e.target.name === "nombre") {
        if (/^\s/.test(e.target.value)) {
          e.target.setCustomValidity("Debe escribir el nombre de la Obra");
        } else {
          e.target.setCustomValidity("");
        }

        setFormDataPb({
          ...formDataPB,
          [e.target.name]: e.target.value,
        });
      } else if (e.target.name === "cantidadVanosPb") {
        if (!Number.isInteger(parseFloat(e.target.value))) {
          e.target.setCustomValidity("Debe escribir un valor entero");
        } else {
          e.target.setCustomValidity("");
        }

        setFormDataPb({
          ...formDataPB,
          ["inputs"]: { ...formDataPB.inputs, [e.target.name]: e.target.value },
        });
      } else if (e.target.name === "cantidadEsquinasPb") {
        if (!Number.isInteger(parseFloat(e.target.value))) {
          e.target.setCustomValidity("Debe escribir un valor entero");
        } else {
          e.target.setCustomValidity("");
        }

        setFormDataPb({
          ...formDataPB,
          ["inputs"]: { ...formDataPB.inputs, [e.target.name]: e.target.value },
        });
      } else {
        setFormDataPb({
          ...formDataPB,
          ["inputs"]: { ...formDataPB.inputs, [e.target.name]: e.target.value },
        });
      }
    } else if (planta === "pa") {
      if (e.target.name === "alturaPromedio") {
        setFormDataEntrepiso({
          ...formDataEntrepiso,
          ["panelExteriorPa"]: {
            ...formDataEntrepiso.panelExteriorPa,
            [e.target.name]: e.target.value,
          },
        });
      } else if (e.target.name === "luzEntrepiso") {
        setFormDataEntrepiso({
          ...formDataEntrepiso,
          ["entrepiso"]: {
            ...formDataEntrepiso.entrepiso,
            [e.target.name]: e.target.value,
          },
        });
      } else if (e.target.name === "cantidadVanosPa") {
        if (!Number.isInteger(parseFloat(e.target.value))) {
          e.target.setCustomValidity("Debe escribir un valor entero");
        } else {
          e.target.setCustomValidity("");
        }

        setFormDataEntrepiso({
          ...formDataEntrepiso,
          ["inputs"]: {
            ...formDataEntrepiso.inputs,
            [e.target.name]: e.target.value,
          },
        });
      } else if (e.target.name === "cantidadEsquinasPa") {
        if (!Number.isInteger(parseFloat(e.target.value))) {
          e.target.setCustomValidity("Debe escribir un valor entero");
        } else {
          e.target.setCustomValidity("");
        }

        setFormDataEntrepiso({
          ...formDataEntrepiso,
          ["inputs"]: {
            ...formDataEntrepiso.inputs,
            [e.target.name]: e.target.value,
          },
        });
      } else if (e.target.name === "nombre") {
        if (/^\s/.test(e.target.value)) {
          e.target.setCustomValidity("Debe escribir el nombre de la Obra");
        } else {
          e.target.setCustomValidity("");
        }

        setFormDataEntrepiso({
          ...formDataEntrepiso,
          [e.target.name]: e.target.value,
        });
      }else {
        setFormDataEntrepiso({
          ...formDataEntrepiso,
          ["inputs"]: {
            ...formDataEntrepiso.inputs,
            [e.target.name]: e.target.value,
          },
        });
      }
    } else if (planta === "tanque") {
      if (e.target.name === "luzEntrepisoTanque") {
        setFormDataEntrepisoTanque({
          ...formDataEntrepisoTanque,
          ["entrepisoTanque"]: {
            ...formDataEntrepisoTanque.entrepisoTanque,
            [e.target.name]: e.target.value,
          },
        });
      } else if (e.target.name === "nombre") {
        if (/^\s/.test(e.target.value)) {
          e.target.setCustomValidity("Debe escribir el nombre de la Obra");
        } else {
          e.target.setCustomValidity("");
        }

        setFormDataEntrepisoTanque({
          ...formDataEntrepisoTanque,
          [e.target.name]: e.target.value,
        });
      } else {
        setFormDataEntrepisoTanque({
          ...formDataEntrepisoTanque,
          ["inputs"]: {
            ...formDataEntrepisoTanque.inputs,
            [e.target.name]: e.target.value,
          },
        });
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    let formPbAux = formDataPB;
    let formEntrepisoAux = null;
    let formEntrepisoTanqueAux = null;

    if (formPbAux.inputs.longitudTotalPanelInteriorPb === "") {
      formPbAux.inputs.longitudTotalPanelInteriorPb = "0";
    }

    if (steelFrameData.tipoCubierta !== "no-aplica") {
      formPbAux.cubierta = {
        tipoCubierta: steelFrameData.tipoCubierta,
        luzCubierta: formDataPB.cubierta.luzCubierta,
      };
    } else {
      formPbAux.cubierta = null;
    }

    if (steelFrameData.cantidadPlantas === "2") {
      formEntrepisoAux = formDataEntrepiso;

      if (steelFrameData.tipoEntrepiso !== "no-aplica") {
        formEntrepisoAux.entrepiso = {
          tipoEntrepiso: steelFrameData.tipoEntrepiso,
          luzEntrepiso: formDataEntrepiso.entrepiso.luzEntrepiso,
        };
      } else {
        formEntrepisoAux.entrepiso = null;
      }

      if (formEntrepisoAux.inputs.longitudTotalPanelInteriorPa === "") {
        formEntrepisoAux.inputs.longitudTotalPanelInteriorPa = "0";
      }
    }

    if (steelFrameData.calculaTanque === "1") {
      formEntrepisoTanqueAux = formDataEntrepisoTanque;
      formEntrepisoTanqueAux.entrepisoTanque = {
        entrepisoTanque: steelFrameData.tipoApoyoTanque,
        luzEntrepisoTanque:
          formDataEntrepisoTanque.entrepisoTanque.luzEntrepisoTanque,
      };
    }

    let dataSteelFrame = {
      id: 8,
      nombre: "Steel Frame",
      slug: "steel-frame",
      inputs: [],
      steelframe: {
        zonaGeografica: steelFrameData.zonaGeografica,
        exposicionViento: steelFrameData.exposicionViento,
        plantaBaja: formPbAux,
        plantaAlta: formEntrepisoAux,
        tanqueReserva: formEntrepisoTanqueAux,
      },
    };

    sessionStorage.setItem(
      DATA_INPUT_STEEL_FRAME,
      JSON.stringify(dataSteelFrame)
    );

    precalcularService([JSON.parse(sessionStorage.getItem(DATA_INPUT_STEEL_FRAME))])
        .then((response) => {
          if (response.length > 0) {
            
            var item = response[0].excelSteelFrame;

              if ((item.cubierta != null && !item.cubierta.perfilCubiertaAdoptado) && (item.entrepiso != null && !item.entrepiso.perfil)){
                setAviso("Con las condiciones ingresadas para la cubierta y el entrepiso no es posible realizar el cómputo. ¿Desea continuar?");
                handleClickOpen();
              }
              else if (item.cubierta != null && !item.cubierta.perfilCubiertaAdoptado){
                setAviso("Con las condiciones ingresadas para la cubierta no es posible realizar el cómputo. ¿Desea continuar?");
                handleClickOpen();
              }
              else if (item.entrepiso != null && !item.entrepiso.perfil){
                setAviso("Con las condiciones ingresadas para el entrepiso no es posible realizar el cómputo. ¿Desea continuar?");
                handleClickOpen();
              }else{
                props.history.push(USER_DATA_URI);
              }
            }
        })
        .catch(function (error) {
          setMessageError(
            "Disculpe las molestias ocasionadas. Estamos trabajando para reestablecer la plataforma."
          );
          setLoading(false);
        });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    setOpen(false);
    props.history.push(USER_DATA_URI);
  };

  return (
    <div className={classes.root}>
      <div className={classes.containerInputPage}>
      <AvisoCalculoSteelFrameModal open={open} handleClose={handleClose} handleContinue={handleContinue} mensaje={aviso}/>
        <Typography className={classes.titleInputPage}>
          Calculador de Materiales
        </Typography>
        <div className={classes.divider}>
          <Divider />
        </div>

        <div className={classes.sectionInputsPage}>
          <Typography className={classes.subtitleInputPage}>
            Sistema Steel Frame
          </Typography>
        </div>

        <form onSubmit={handleSubmit}>
          <div className={classes.formPB}>
            <FormPlantaBaja
              fields={formDataPB}
              tipoCubierta={steelFrameData.tipoCubierta}
              handleInputChange={handleInputChange}
            />
          </div>
          {steelFrameData.cantidadPlantas === "2" ? (
            <div className={classes.formEntrepiso}>
              <FormEntrepiso
                fields={formDataEntrepiso}
                tipoEntrepiso={steelFrameData.tipoEntrepiso}
                handleInputChange={handleInputChange}
              />
            </div>
          ) : (
            <div />
          )}
          {steelFrameData.calculaTanque === "1" ? (
            <div className={classes.formEntrepisoTanque}>
              <FormEntrepisoTanque
                fields={formDataEntrepisoTanque}
                handleInputChange={handleInputChange}
              />
            </div>
          ) : (
            <div />
          )}
          <Grid container className={classes.containerButtons} spacing={10}>
            <Grid className={classes.buttonBack} item xs={6}>
              <ButtonBack
                onClick={() => redirectBack()}
                color="default"
                variant="outlined"
                className={classes.button}
              >
                Atrás
              </ButtonBack>
            </Grid>
            <Grid className={classes.buttonInput} item xs={6}>
              <ButtonSubmit
                type="submit"
                value="Submit"
                color="secondary"
                variant="contained"
                className={classes.button}
              >
                Continuar
              </ButtonSubmit>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

export default InputsPage;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 5,
    overflow: "hidden",
    paddingLeft: "20%",
    paddingRight: "20%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "3%",
      paddingRight: "3%",
    },
  },
  titleInputPage: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#022A3A",
  },
  subtitleInputPage: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "#022A3A",
  },
  containerInputPage: {
    position: "relative",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(8),
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  sectionInputsPage: {
    paddingTop: 20,
    paddingBottom: 30,
  },
  formEntrepiso: {
    paddingTop: 15,
  },
  formEntrepisoTanque: {
    paddingTop: 15,
  },

  buttonBack: {
    textAlign: "right",
  },
  buttonInput: {
    textAlign: "left",
  },
  containerButtons: {
    paddingTop: 100,
    paddingBottom: 50,
  },
}));

const ButtonSubmit = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "white",
    backgroundColor: "#022A3A",
    borderColor: "#022A3A",
    "&:hover": {
      backgroundColor: "#022a44",
      borderColor: "#022a44",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#022a44",
      borderColor: "#022a44",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(2,42,58,.5)",
    },
  },
})(Button);

const ButtonBack = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "#5B6670",
    backgroundColor: "transparent",
    borderColor: "#5B6670",
    "&:hover": {
      backgroundColor: "rgba(91,102,112,.1)",
      borderColor: "#5B6670",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "transparent",
      borderColor: "#5B6670",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(91,102,112,.5)",
    },
  },
})(Button);
