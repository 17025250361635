import React, { useEffect } from "react";
import InputBase from "@material-ui/core/InputBase";
import Select from "@material-ui/core/Select";
import getCampoOfSystem from "../Services/getCampoOfSystem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormZocaloLargoAmbiente from "./Zocalos/FormZocaloLargoAmbiente";
import FormZocaloLargoParedes from "./Zocalos/FormZocaloLargoParedes";  
import EditIcon from '@material-ui/icons/Edit';

export default function FormZocalo(props) {
  const classes = useStyles();
  const {
    fields,
    handleInputChange,
    sistema,
    idSistema,
    index,
    handleAddMedidaClick,
    handleRemoveMedidaClick,
    handleInputMedidasChange,
  } = props;
  const [colors, setColors] = React.useState([]);
  const [tiposZocalos, setTipoZocalos] = React.useState([]);
  const [tipoCalculos, setTipoCalculos] = React.useState([]);
  
  useEffect(() => {
    if (colors.length === 0) {
      getCampoOfSystem(idSistema, "color")
        .then((response) => {
          setColors(response);
        })
        .catch(function (error) {
          console.log("Error al cargar los sistemas");
        });
    }

    if (tiposZocalos.length === 0) {
      getCampoOfSystem(idSistema, "tipoZocalo")
        .then((response) => {
          setTipoZocalos(response);
        })
        .catch(function (error) {
          console.log("Error al cargar los sistemas");
        });
    }

    if (tipoCalculos.length === 0) {
      getCampoOfSystem(idSistema, "tipoCalculo")
        .then((response) => {
          setTipoCalculos(response);
        })
        .catch(function (error) {
          console.log("Error al cargar los sistemas");
        });
    }
  }, [setColors, setTipoCalculos, setTipoZocalos]);

  return (
    <div>
      <div className="row">
        <div className="column icon">
        <EditIcon className={classes.iconEdit}/>
        </div>
        <div className="column name-calculo">
          <input
            type="text"
            required
            name="nombre"
            maxLength="31"
            value={fields.nombre}
            onChange={(e) => handleInputChange(e, sistema, index)}
          />
          <Typography className={classes.textEdit}>
            Puede editar el nombre del cálculo
          </Typography>
        </div>
        
      </div>
      <Grid container spacing={5} className={classes.gridMain}>
        <Grid className={classes.gridSectionFields} item xs={6}>
          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Seleccione el tipo de
              cálculo
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <Select
                native
                className={classes.autocomplete}
                value={fields.tipoCalculo}
                onChange={(e) => handleInputChange(e, sistema, index)}
                required
                input={<DesignInput />}
                fullWidth
                inputProps={{
                  name: "tipoCalculo",
                  id: "outlined-tipo-calculo",
                }}
              >
                <option value="">Seleccionar</option>
                {tipoCalculos &&
                  tipoCalculos.map((tipo, i) => (
                    <option key={i} value={tipo.slug}>
                      {tipo.valor}
                    </option>
                  ))}
              </Select>
            </Grid>

            <Grid className={classes.gridReference} item xs={2}></Grid>
          </Grid>
        </Grid>
      </Grid>
      {fields.tipoCalculo === "por-largo-ancho-ambiente" ? (
        <FormZocaloLargoAmbiente
          fields={fields}
          handleInputChange={handleInputChange}
          sistema={sistema}
          index={index}
          colors={colors}
          tiposZocalos={tiposZocalos}
        />
      ) : fields.tipoCalculo === "por-largo-paredes" ? (
        <FormZocaloLargoParedes
          fields={fields}
          handleInputChange={handleInputChange}
          sistema={sistema}
          index={index}
          colors={colors}
          tiposZocalos={tiposZocalos}
          handleAddMedidaClick={handleAddMedidaClick}
          handleRemoveMedidaClick={handleRemoveMedidaClick}
          handleInputMedidasChange={handleInputMedidasChange}
        />
      ) : (
        ""
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: "400",
    color: "black",
    paddingBottom: 10,
  },
  iconEdit: {
    color: "#2b323c",
    marginTop: 2,
  },
  textEdit:{
    color: "#2b323c",
    fontSize: "16px",
    fontWeight: "bold",
  },
  referencia: {
    fontSize: "16px",
    fontWeight: "400",
    color: "black",
  },
  required: {
    color: "red",
  },
  field: {
    paddingTop: 6,
  },
  iconHelp: {
    color: "#5B6670",
    marginTop: 20,
  },
  gridMain: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  gridReferencia: {
    paddingTop: 30,
  },
  gridInputField: {
    paddingBottom: 5,
  },
  gridLabel: {
    paddingTop: 10,
  },
  gridSectionImages: {
    padding: 20,
  },
  imageReference: {
    textAlign: "center",
  },
  imgLargo: {
    position: "relative",
    paddingTop: 10,
  },
  imgAncho: {
    position: "relative",
    paddingTop: 20,
  },
}));

const DesignInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "18px 26px 18px 12px",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
