import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import HelpIcon from "@material-ui/icons/Help";
import EditIcon from '@material-ui/icons/Edit';
import { Divider } from "@material-ui/core";


function FormPlantaBaja(props) {
  const classes = useStyles();
  const { fields, handleInputChange, tipoCubierta } = props;
  const [reference, setReference] = React.useState("");

  return (
    <div>
      <div className="row">
        <div className="column icon">
        <EditIcon className={classes.iconEdit}/>
        </div>
        <div className="column name-calculo">
          <input
            type="text"
            required
            name="nombre"
            maxLength="60"
            value={fields.nombre}
            onChange={(e) => handleInputChange(e, "pb")}
          />
          <Typography className={classes.textEdit}>
            Puede editar el nombre del proyecto
          </Typography>
        </div>
        
      </div>
      <div>
      <Typography className={classes.textPlanta}>
            Planta 1
          </Typography>
          <Divider/>
      </div>
      <Grid container spacing={5} className={classes.gridMain}>
        <Grid className={classes.gridSectionFields} item xs={6}>
          <Grid className={classes.gridLabelFirst} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Superficie (m2){" "}
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m2"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.inputs.superficiePb}
                onChange={(e) => handleInputChange(e, "pb")}
                name="superficiePb"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step:"0.01",
                    min: 0,
                    max: 99999
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("superficiePb")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              Longitud total de paneles interiores (m){" "}
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.inputs.longitudTotalPanelInteriorPb}
                onChange={(e) => handleInputChange(e, "pb")}
                name="longitudTotalPanelInteriorPb"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  type: "number",
                  inputProps: {
                    step:"0.01",
                    min: 0,
                    max: 99999
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("longitudTotalPanelInteriorPb")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Longitud total de
              paneles exteriores (m){" "}
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.inputs.longitudTotalPanelExteriorPb}
                onChange={(e) => handleInputChange(e, "pb")}
                name="longitudTotalPanelExteriorPb"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step:"0.01",
                    min: 0,
                    max: 99999
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("longitudTotalPanelExteriorPb")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Longitud de vanos (m){" "}
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.inputs.longitudVanosPb}
                onChange={(e) => handleInputChange(e, "pb")}
                name="longitudVanosPb"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step:"0.01",
                    min: 0,
                    max: 99999
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("longitudVanosPb")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Cantidad de vanos{" "}
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.inputs.cantidadVanosPb}
                onChange={(e) => handleInputChange(e, "pb")}
                name="cantidadVanosPb"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    min: 1,
                    max: 99999
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("cantidadVanosPb")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Cantidad de esquinas{" "}
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.inputs.cantidadEsquinasPb}
                onChange={(e) => handleInputChange(e, "pb")}
                name="cantidadEsquinasPb"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    min: 1,
                    max: 99999
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("cantidadEsquinasPb")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Altura promedio de
              paneles{" "}
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.panelExteriorPb.alturaPromedio}
                onChange={(e) => handleInputChange(e, "pb")}
                name="alturaPromedio"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step:"0.01",
                    max: 4.20,
                    min: 0,
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("alturaPromedio")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          {tipoCubierta !== "no-aplica" && (
            <Grid>
              <Grid className={classes.gridLabel} item xs={12}>
                <Typography className={classes.label}>
                  <span className={classes.required}>*</span> Luz de Cubierta
                  (m)
                </Typography>
              </Grid>
              <Grid container spacing={3} className={classes.gridInputField}>
                <Grid className={classes.gridField} item xs={10}>
                  <TextField
                    placeholder="Ingrese la luz de cubierta en metros"
                    fullWidth
                    margin="none"
                    className={classes.field}
                    value={fields.cubierta.luzCubierta}
                    onChange={(e) => handleInputChange(e, "pb")}
                    name="luzCubierta"
                    InputLabelProps={{
                      shrink: false,
                    }}
                    InputProps={{
                      required: true,
                      type: "number",
                      inputProps: {
                        step:"0.01",
                        max: 7,
                        min: 0,
                      },
                    }}
                    variant="outlined"
                  />
                </Grid>

                <Grid className={classes.gridReference} item xs={2}>
                  <HelpIcon
                    onMouseOver={() => setReference("luzCubierta")}
                    onMouseOut={() => setReference("")}
                    className={classes.iconHelp}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid className={classes.gridSectionImages} item xs={6}>
          <div className={classes.imageReference}>
            <div className={classes.imgSup}>
              {reference === "superficiePb" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/superficiePb.jpg")}
                  alt="Superficie"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgLongExt}>
              {reference === "longitudTotalPanelExteriorPb" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/longitudTotalPanelExteriorPb.jpg")}
                  alt="Longitud Total Panel Exterior"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgLongInt}>
              {reference === "longitudTotalPanelInteriorPb" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/longitudTotalPanelInteriorPb.jpg")}
                  alt="Longitud Total Panel Interior"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgLongVan}>
              {reference === "longitudVanosPb" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/longitudVanosPb.jpg")}
                  alt="Longitud Vanos"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgCantVan}>
              {reference === "cantidadVanosPb" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/cantidadVanosPb.jpg")}
                  alt="Cantidad Vanos"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgCantEsq}>
              {reference === "cantidadEsquinasPb" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/cantidadEsquinasPb.jpg")}
                  alt="Cantidad Esquinas"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgAlturaPromedio}>
              {reference === "alturaPromedio" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/alturaPromedioPb.jpg")}
                  alt="Altura Promedio"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgLuzCubierta}>
              {reference === "luzCubierta" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/steel-frame/luzCubierta.jpg")}
                  alt="Luz Cubierta"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default FormPlantaBaja;

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: "400",
    color: "black",
    paddingBottom: 10,
  },
  required: {
    color: "red",
  },
  field: {
    paddingTop: 6,
  },
  iconHelp: {
    color: "#5B6670",
    marginTop: 20,
  },
  gridMain: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  gridInputField: {
    paddingBottom: 5,
  },
  gridLabel: {
    paddingTop: 20,
  },
  gridSectionImages: {
    padding: 20,
  },
  imageReference: {
    textAlign: "center",
  },
  imgSup: {
    position: "relative",
    paddingTop: 10,
  },
  imgLongInt: {
    position: "relative",
    paddingTop: 20,
  },
  imgLongExt: {
    position: "relative",
    paddingTop: 30,
  },
  imgLongVan: {
    position: "relative",
    paddingTop: 75,
  },
  imgCantVan: {
    position: "relative",
    paddingTop: 75,
  },
  imgCantEsq: {
    position: "relative",
    paddingTop: 75,
  },
  imgAlturaPromedio: {
    position: "relative",
    paddingTop: 75,
  },
  imgLuzCubierta: {
    position: "relative",
    paddingTop: 85,
  },
  iconEdit: {
    color: "#2b323c",
    marginTop: 2,
  },
  textEdit:{
    color: "#2b323c",
    fontSize: "16px",
    fontWeight: "bold",
  },
  textPlanta:{
    color: "#2b323c",
    fontSize: "22px",
    fontWeight: "900",
  },
}));
