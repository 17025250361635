import axios from 'axios'

const baseURL = process.env.API_BASE_URL;

export default function authenticateService (credentials) {
  return axios.post(baseURL + 'usuariosbackoffice/authenticate', credentials)
        .then(response => response)
        .catch(error => {
          throw error;
        });
}