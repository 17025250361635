import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AgrupacionComponent from "../Resultados/AgrupacionComponent";
import MaterialResultadoComponent from "../Resultados/MaterialResultadoComponent";

export default function FormResultDrywallPared(props) {
  const classes = useStyles();
  const { resultado } = props;
  const [campos, setCampos] = React.useState([]);

  useEffect(() => {
    if (campos.length === 0) {
      let nuevoObjeto1 = {};
      let nuevoObjeto2 = {};
      let nuevoObjeto3 = {};
      let aux = [];
      let id_agrupacion1 = "";
      let id_agrupacion2 = "";
      let id_agrupacion3 = "";

      resultado.forEach((x) => {
        if (x.agrupacion === "Perfiles") {
          if (id_agrupacion1 !== 1) {
            id_agrupacion1 = 1;
            nuevoObjeto1 = {
              agrupacion: x.agrupacion,
              id_agrupacion: id_agrupacion1,
              resultado: [],
            };
          }

          nuevoObjeto1.resultado.push({
            id: x.id,
            nombre: x.nombre,
            slug: x.slug,
            unidades: x.unidades,
            paquetes: x.paquetes,
            total: x.total,
            tiras: x.tiras,
            esComerciable: x.esComerciable
          });
        } else if (x.agrupacion === "Tornillos") {
          if (id_agrupacion2 !== 2) {
            id_agrupacion2 = 2;
            nuevoObjeto2 = {
              agrupacion: x.agrupacion,
              id_agrupacion: id_agrupacion2,
              resultado: [],
            };
          }

          nuevoObjeto2.resultado.push({
            id: x.id,
            nombre: x.nombre,
            slug: x.slug,
            unidades: x.unidades,
            paquetes: x.paquetes,
            total: x.total,
            tiras: x.tiras,
            unidad_medida: "unidades",
            esComerciable: x.esComerciable
          });
        } else {
          if (id_agrupacion3 !== 3) {
            id_agrupacion3 = 3;
            nuevoObjeto3 = {
              agrupacion: x.agrupacion,
              id_agrupacion: id_agrupacion3,
              resultado: [],
            };
          }

          if (x.slug === "placas-yeso-12-5") {
            nuevoObjeto3.resultado[0] = {
              id: x.id,
              nombre: x.nombre,
              slug: x.slug,
              unidades: x.unidades,
              paquetes: x.paquetes,
              total: x.total,
              tiras: x.tiras,
              unidad_medida: "unidades",
              esComerciable: x.esComerciable
            };
          } else if (x.slug === "masilla") {
            nuevoObjeto3.resultado[1] = {
              id: x.id,
              nombre: x.nombre,
              slug: x.slug,
              unidades: x.unidades,
              paquetes: x.paquetes,
              total: x.total,
              tiras: x.tiras,
              unidad_medida: "kilogramos",
              esComerciable: x.esComerciable
            };
          } else if (x.slug === "cinta-papel") {
            nuevoObjeto3.resultado[2] = {
              id: x.id,
              nombre: x.nombre,
              slug: x.slug,
              unidades: x.unidades,
              paquetes: x.paquetes,
              total: x.total,
              tiras: x.tiras,
              unidad_medida: "metros",
              esComerciable: x.esComerciable
            };
          } else if (x.slug === "enduido") {
            nuevoObjeto3.resultado[3] = {
              id: x.id,
              nombre: x.nombre,
              slug: x.slug,
              unidades: x.unidades,
              paquetes: x.paquetes,
              total: x.total,
              tiras: x.tiras,
              unidad_medida: "litros",
              esComerciable: x.esComerciable
            };
          } else if (x.slug === "aislacion-termica") {
            nuevoObjeto3.resultado[4] = {
              id: x.id,
              nombre: x.nombre,
              slug: x.slug,
              unidades: x.unidades,
              paquetes: x.paquetes,
              total: x.total,
              tiras: x.tiras,
              unidad_medida: "metros cuadrados",
              esComerciable: x.esComerciable
            };
          } else {
            nuevoObjeto3.resultado[5] = {
              id: x.id,
              nombre: x.nombre,
              slug: x.slug,
              unidades: x.unidades,
              paquetes: x.paquetes,
              total: x.total,
              tiras: x.tiras,
              unidad_medida: "metros",
              esComerciable: x.esComerciable
            };
          }
        }
      });
      aux.push(nuevoObjeto1);
      aux.push(nuevoObjeto2);
      aux.push(nuevoObjeto3);
      setCampos(aux);
    }
  });

  return (
    <div className="container">
      {campos &&
        campos.map((output, index) => {
          return (
            <div key={index}>
              <div className={classes.agrupacion}>
                <AgrupacionComponent agrupacion={output.agrupacion} />
              </div>
              {output.resultado.map((x, i) => {
                return (
                  <div key={i} className={classes.resultadoMaterial}>
                    <MaterialResultadoComponent
                      name={x.nombre}
                      cantidad={
                        output.id_agrupacion === 1
                          ? Math.round(x.total * 10) / 10
                          : x.unidades
                      }
                      unidad={
                        output.id_agrupacion === 1 ? "metros" : x.unidad_medida
                      }
                      otraCantidad={output.id_agrupacion === 1 ? x.tiras : "NA"}
                      otraUnidad={"tiras"}
                      esComerciable={x.esComerciable}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 5,
    overflow: "hidden",
    padding: theme.spacing(0, 35),
  },
  titleResultPage: {
    fontSize: "30px",
    fontWeight: "bold",
    color: "#022A3A",
  },
  sectionSubtitle: {
    paddingTop: 20,
    paddingbottom: 20,
  },
  subtitle: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "black",
  },
  sectionReferences: {
    paddingTop: 20,
    paddingBottom: 10,
  },
  containerResultPage: {
    position: "relative",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(8),
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  buttonBackInit: {
    textAlign: "end",
  },
  sectionObra: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  nombreObra: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  agrupacion: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  resultadoMaterial: {
    paddingTop: 20,
    paddingBottom: 20,
  },
}));
