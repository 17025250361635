import React, { useEffect } from "react";
import InputBase from "@material-ui/core/InputBase";
import Select from "@material-ui/core/Select";
import getCampoOfSystem from "../Services/getCampoOfSystem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import HelpIcon from "@material-ui/icons/Help";
import EditIcon from '@material-ui/icons/Edit';

export default function FormSuspensionesTx(props) {
  const classes = useStyles();
  const { fields, handleInputChange, sistema, idSistema, index } = props;
  const [tipoPerfiles, setTipoPerfiles] = React.useState([]);
  const [distanciaPerfiles, setDistanciaPerfiles] = React.useState([]);
  const [grillasPlacas, setGrillasPlacas] = React.useState([]);
  const [reference, setReference] = React.useState("");

  useEffect(() => {
    if (tipoPerfiles.length === 0) {
      getCampoOfSystem(7, "tipoPerfil")
        .then((response) => {
          setTipoPerfiles(response);
        })
        .catch(function (error) {
          console.log("Error al cargar los valores de modulacion");
        });
    }
    if (distanciaPerfiles.length === 0) {
      getCampoOfSystem(7, "distanciaPerfil")
        .then((response) => {
          setDistanciaPerfiles(response);
        })
        .catch(function (error) {
          console.log("Error al cargar los valores de colores");
        });
    }
    if (grillasPlacas.length === 0) {
      getCampoOfSystem(7, "grillasPlacas")
        .then((response) => {
          setGrillasPlacas(response);
        })
        .catch(function (error) {
          console.log("Error al cargar los valores de colores");
        });
    }
  }, [setTipoPerfiles, setDistanciaPerfiles, setGrillasPlacas]);

  return (
    <div>
      <div className="row">
        <div className="column icon">
        <EditIcon className={classes.iconEdit}/>
        </div>
        <div className="column name-calculo">
          <input
            type="text"
            required
            name="nombre"
            maxLength="31"
            value={fields.nombre}
            onChange={(e) => handleInputChange(e, sistema, index)}
          />
          <Typography className={classes.textEdit}>
            Puede editar el nombre del cálculo
          </Typography>
        </div>
        
      </div>
      <Grid container spacing={5} className={classes.gridMain}>
        <Grid className={classes.gridSectionFields} item xs={6}>
          <Grid className={classes.gridLabelFirst} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Lado mayor del
              ambiente (m)
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.largo}
                onChange={(e) => handleInputChange(e, sistema, index)}
                name="largo"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step: "0.01",
                    min: "0",
                    max: "9999",
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("largo")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Lado menor del
              ambiente (m)
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.ancho}
                onChange={(e) => handleInputChange(e, sistema, index)}
                name="ancho"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step: "0.01",
                    min: "0",
                    max: "9999",
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("ancho")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              Desperdicio sugerido 5%
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.desperdicio}
                onChange={(e) => handleInputChange(e, sistema, index)}
                name="desperdicio"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    min: "0",
                    max: "100",
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}></Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Tipo de perfil
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <Select
                native
                className={classes.autocomplete}
                value={fields.tipoPerfil}
                onChange={(e) => handleInputChange(e, sistema, index)}
                required
                input={<DesignInput />}
                fullWidth
                inputProps={{
                  name: "tipoPerfil",
                  id: "outlined-tipo-perfil",
                }}
              >
                <option value="">Seleccionar</option>
                {tipoPerfiles &&
                  tipoPerfiles.map((tipo, i) => (
                    <option key={i} value={tipo.slug}>
                      {tipo.valor}
                    </option>
                  ))}
              </Select>
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("tipoPerfil")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Distancia entre
              largueros
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <Select
                native
                className={classes.autocomplete}
                value={fields.distanciaPerfil}
                onChange={(e) => handleInputChange(e, sistema, index)}
                required
                input={<DesignInput />}
                fullWidth
                inputProps={{
                  name: "distanciaPerfil",
                  id: "outlined-tipo-perfil",
                }}
              >
                <option value="">Seleccionar</option>
                {distanciaPerfiles &&
                  distanciaPerfiles.map((distancia, i) => (
                    <option key={i} value={distancia.slug}>
                      {distancia.valor}
                    </option>
                  ))}
              </Select>
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("distanciaPerfil")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
              </Grid>
          </Grid>
          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Tipos de Placas
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <Select
                native
                className={classes.autocomplete}
                value={fields.grillasPlacas}
                onChange={(e) => handleInputChange(e, sistema, index)}
                required
                input={<DesignInput />}
                fullWidth
                inputProps={{
                  name: "grillasPlacas",
                  id: "outlined-grillas-placas",
                }}
              >
                <option value="">Seleccionar</option>
                {grillasPlacas &&
                  grillasPlacas.map((grilla, i) => (
                    <option key={i} value={grilla.slug}>
                      {grilla.valor}
                    </option>
                  ))}
              </Select>
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("grillasPlacas")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
              </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.gridSectionImages} item xs={6}>
          <div className={classes.imageReference}>
            <div className={classes.imgLargo}>
              {reference === "largo" ? (
                <img
                  width="80%"
                  height="auto"
                  src={require("../../assets/images/suspensiones-tx/largo.jpg")}
                  alt="Lado Mayor del ambiente Suspensiones TX"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgAncho}>
              {reference === "ancho" ? (
                <img
                  width="80%"
                  height="auto"
                  src={require("../../assets/images/suspensiones-tx/ancho.jpg")}
                  alt="Lado Menor del ambiente Suspensiones TX"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgTipoPerfil}>
              {reference === "tipoPerfil" ? (
                <img
                  width="80%"
                  height="auto"
                  src={require("../../assets/images/suspensiones-tx/tipo_perfil.jpg")}
                  alt="Tipo de perfiles"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgDistanciaLarguero}>
              {reference === "distanciaPerfil" ? (
                <img
                  width="80%"
                  height="auto"
                  src={require("../../assets/images/suspensiones-tx/distancia_largueros.jpg")}
                  alt="Distancia entre largueros"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgTipoPlacas}>
              {reference === "grillasPlacas" ? (
                <img
                  width="80%"
                  height="auto"
                  src={require("../../assets/images/suspensiones-tx/tipo_placas.jpg")}
                  alt="Tipo de placas"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: "400",
    color: "black",
    paddingBottom: 10,
  },
  referencia: {
    fontSize: "16px",
    fontWeight: "400",
    color: "black",
  },
  required: {
    color: "red",
  },
  field: {
    paddingTop: 6,
  },
  iconHelp: {
    color: "#5B6670",
    marginTop: 20,
  },
  gridMain: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  gridReferencia: {
    paddingTop: 30,
  },
  gridInputField: {
    paddingBottom: 5,
  },
  gridLabel: {
    paddingTop: 20,
  },
  gridSectionImages: {
    padding: 20,
  },
  imageReference: {
    textAlign: "center",
  },
  imgLargo: {
    position: "relative",
    paddingTop: 10,
  },
  imgAncho: {
    position: "relative",
    paddingTop: 20,
  },
  imgTipoPerfil: {
    position: "relative",
    paddingTop: 25,
  },
  imgDistanciaLarguero: {
    position: "relative",
    paddingTop: 30,
  },
  imgTipoPlacas: {
    position: "relative",
    paddingTop: 35,
  },
  iconEdit: {
    color: "#2b323c",
    marginTop: 2,
  },
  textEdit:{
    color: "#2b323c",
    fontSize: "16px",
    fontWeight: "bold",
  },


   
}));

const DesignInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "18px 26px 18px 12px",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
