import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";

function AgrupacionComponent(props) {
  const classes = useStyles();
  const {agrupacion} = props;

  return (
      <div>
        <Typography className={classes.agrupacion}>
          {agrupacion}
        </Typography>
        <div className={classes.divider}>
          <Divider />
        </div>
    </div>
  );
}

export default AgrupacionComponent;

const useStyles = makeStyles((theme) => ({
  agrupacion: {
    fontSize: "22px",
    fontWeight: "500",
    color: "black",
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));
