import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

function NombreObraComponent(props) {
  const classes = useStyles();
  const {name} = props;

  return (
      <div>
        <Typography className={classes.name}>
          {name}
        </Typography>        
    </div>
  );
}

export default NombreObraComponent;

const useStyles = makeStyles((theme) => ({
  name: {
    fontSize: "24px",
    fontWeight: "900",
    color: "black",
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));
