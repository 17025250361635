import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DescriptionAlerts from "../../components/DescriptionAlerts";
import { USER_DATA_URI, HOME_URI } from "../../routes";
import descargarExcelService from "../../components/Services/descargarExcelService";
import NombreObraComponent from "../../components/Resultados/NombreObraComponent";
import AgrupacionComponent from "../../components/Resultados/AgrupacionComponent";
import LoadingPage from "../../components/LoadingPage";
import calcularService from "../../components/Services/calcularService";
import comprarMaterialesMagento from "../../components/Services/comprarMaterialesMagento";
import MaterialResultadoComponent from "../../components/Resultados/MaterialResultadoComponent";
import PerfilesSFResultadoComponent from "../../components/Resultados/PerfilesSFResultadoComponent";
import getProvinciasService from "../../components/Services/getProvinciasService";
import CategoriaAgrupadaResultadoComponent from "../../components/Resultados/CategoriaAgrupadaResultadoComponent";

export const DATA_INPUT_STEEL_FRAME = "data_input_steelFrame";
export const DATA_USER = "data_user";
export const SISTEMAS_SELECCIONADOS = "sistemas";
export const DATA_STEELFRAME = "steelFrameData";

function ResultadoSteelFramePage(props) {
  const classes = useStyles();
  const [dataResults, setDataResults] = React.useState([]);
  const [messageError, setMessageError] = React.useState("");
  const [inputs, setInputs] = React.useState([
    JSON.parse(sessionStorage.getItem(DATA_INPUT_STEEL_FRAME)),
  ]);
  const [aislaciones, setAislaciones] = React.useState({
    aislacionTermica: {
      name: "Aislación Térmica",
      nameA1: "",
      cantidadA1: "",
      unidadA1: "metros cuadrados",
      nameA2: "",
      cantidadA2: "",
      unidadA2: "metros cuadrados",
      total: "",
      totalUnidad: "metros cuadrados",
    },
  });

  let [loading, setLoading] = React.useState(true);

  function _getDataPrintExcel() {
    return {
      id: inputs[0].id,
      nombre: inputs[0].nombre,
      email: JSON.parse(sessionStorage.getItem(DATA_USER)).email,
      nombreCliente: JSON.parse(sessionStorage.getItem(DATA_USER)).nombre,
      slug: inputs[0].slug,
      inputsSteelFrame: inputs[0].steelframe,
      resultadosSteelFrame: dataResults[0].resultadosSteelFrame,
      excelSteelFrame: dataResults[0].excelSteelFrame,
    };
  }

  const descargarExcel = () => {
    setLoading(true);
    descargarExcelService(_getDataPrintExcel(), "Steel Frame")
      .then((response) => {
        let respuesta = response;
        setLoading(false);
      })
      .catch(function (error) {
        setMessageError(
          "Disculpe las molestias ocasionadas. Estamos trabajando para reestablecer la plataforma."
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dataResults.length === 0) {
      calcularService(inputs)
        .then((response) => {
          let respuesta = response;
          respuesta.forEach((element) => 
            element.SistemaConstruccionSteelFrameSeleccionadoId= inputs.find((res) => res.slug == element.slug).SistemaConstruccionSteelFrameSeleccionadoId
          );
          setDataResults(respuesta);
          setLoading(false);
        })
        .catch(function (error) {
          setMessageError(
            "Disculpe las molestias ocasionadas. Estamos trabajando para reestablecer la plataforma."
          );
          setLoading(false);
        });
    }
  });

  const redirectToBack = () => {
    sessionStorage.removeItem(DATA_INPUT_STEEL_FRAME);
    sessionStorage.removeItem(DATA_USER);
    sessionStorage.removeItem(SISTEMAS_SELECCIONADOS);
    sessionStorage.removeItem(DATA_STEELFRAME);    
    props.history.push(USER_DATA_URI);
  };

  const redirectToHome = () => {
    sessionStorage.removeItem(DATA_INPUT_STEEL_FRAME);
    sessionStorage.removeItem(DATA_USER);
    sessionStorage.removeItem(SISTEMAS_SELECCIONADOS);
    sessionStorage.removeItem(DATA_STEELFRAME);    
    props.history.push(HOME_URI);
  };

  function _getMaterialesSKU() {
    let auxiliar = [];

    dataResults.map((res) => {
      let index = 0;
      let objeto = {};
      objeto.id_sistema = res.id;
      objeto.SistemaConstruccionSteelFrameSeleccionadoId = res.SistemaConstruccionSteelFrameSeleccionadoId
      objeto.campo_select = null;
      objeto.materiales = [];

      res.resultadosSteelFrame.perfilesPgc.map((a) => {
        let matAux = {};
        matAux.id_material = a.id;
        matAux.cantidad = a.subtotalUnidad;

        objeto.materiales.push(matAux);
      });

      res.resultadosSteelFrame.perfilesPgu.map((a) => {
        let matAux = {};

        if (a.totalKg !== 0) {
          matAux.id_material = a.id;
          matAux.cantidad = a.subtotalUnidad;
          objeto.materiales.push(matAux);
        }
      });

      res.resultadosSteelFrame.flejes.map((a) => {
        let matAux = {};
        matAux.id_material = a.id;
        matAux.cantidad = Math.ceil(a.totalUnidad);

        objeto.materiales.push(matAux);
      });

      res.resultadosSteelFrame.anclajes.map((a) => {
        let matAux = {};
        if (a.totalUnidad !== 0) {
          matAux.id_material = a.id;
          matAux.cantidad = a.totalUnidad;

          objeto.materiales.push(matAux);
        }
      });

      res.resultadosSteelFrame.tornillosAutoperformantes.map((a) => {
        let matAux = {};
        if (a.totalUnidad !== 0) {
          matAux.id_material = a.id;
          matAux.cantidad = a.totalUnidad;

          objeto.materiales.push(matAux);
        }
      });

      res.resultadosSteelFrame.angulos.map((a) => {
        let matAux = {};
        if (a.totalUnidad !== 0) {
          matAux.id_material = a.id;
          matAux.cantidad = a.totalUnidad;

          objeto.materiales.push(matAux);
        }
      });

      res.resultadosSteelFrame.aislaciones.map((a) => {
        let matAux = {};

        if (a.totalMetrosCuadrados !== 0) {

          if (a.slugMaterial==='barrera-agua-viento-en-paredes-exteriores'){
            matAux.id_material = a.id;
            matAux.cantidad = Math.ceil(a.totalMetrosCuadrados/120);
          }else if (a.slugMaterial==='barrera-agua-viento-en-techo'){
            matAux.id_material = a.id;
            matAux.cantidad = Math.ceil(a.totalMetrosCuadrados/27);
          }else {
            matAux.id_material = a.id;
            matAux.cantidad = a.totalMetrosCuadrados;
          }

          objeto.materiales.push(matAux);
        }

        if (
          a.slugMaterial ===
            "aislacion-hidraulica-y-acustica-perimentral-en-pb-bajo-solera" &&
          a.totalMetrosLineales !== 0
        ) {
          matAux.id_material = a.id;
          matAux.cantidad = Math.ceil(a.totalUnidad);

          objeto.materiales.push(matAux);
        }

        if (
          a.slugMaterial === "aislacion-acustica-perfiles-ep-cubierta-humeda" &&
          a.totalMetrosLineales !== 0
        ) {
          matAux.id_material = a.id;
          matAux.cantidad = Math.ceil(a.totalUnidad);

          objeto.materiales.push(matAux);
        }

        if (
          a.slugMaterial === "banda-flexible-aislacion-vanos" &&
          a.totalUnidad !== 0
        ) {
          matAux.id_material = a.id;
          matAux.cantidad = a.totalUnidad;

          objeto.materiales.push(matAux);
        }

        if (
          a.slugMaterial === "cinta-solape-de-agua-y-viento" &&
          a.totalUnidad !== 0
        ) {
          matAux.id_material = a.id;
          matAux.cantidad = a.totalUnidad;

          objeto.materiales.push(matAux);
        }
      });

      res.resultadosSteelFrame.diafragmasRigidizacion.map((a) => {
        let matAux = {};
        if (a.totalMetrosCuadrados !== 0) {
          matAux.id_material = a.id;
          matAux.cantidad = a.totalMetrosCuadrados;

          objeto.materiales.push(matAux);
        }
      });

      res.resultadosSteelFrame.placasRocaYeso.map((a) => {
        let matAux = {};
        if (a.totalMetrosCuadrados !== 0) {
          matAux.id_material = a.id;
          matAux.cantidad = a.totalMetrosCuadrados;

          objeto.materiales.push(matAux);
        }
      });

      res.resultadosSteelFrame.terminacionExterior.map((a) => {
        let matAux = {};
        if (a.totalMetrosCuadrados !== 0) {

          if (a.slugMaterial==='omega-pgo-37-0-9-colocacion-cubierta'){
            matAux.id_material = a.id;
            matAux.cantidad = Math.ceil(a.totalMetrosCuadrados/6);
          }else{
            matAux.id_material = a.id;
            matAux.cantidad = a.totalMetrosCuadrados;
          }

          objeto.materiales.push(matAux);
        }
      });

      index = index + 1;
      auxiliar.push(objeto);
    });

    return auxiliar;
  }

  const comprarMateriales = () => {
    setLoading(true);
    comprarMaterialesMagento(_getMaterialesSKU())
      .then((response) => {
        let respuesta = response;

        if (respuesta.url) {
          window.open(respuesta.url, "_blank");
        } else {
          console.log(error);
          setMessageCarritoError(
            "No se pudieron agregar los productos al carrito de compra. Vuelva a intertarlo nuevamente."
          );
        }

        setLoading(false);
      })
      .catch(function (error) {
        setMessageError(
          "Disculpe las molestias ocasionadas. Estamos trabajando para reestablecer la plataforma."
        );
        setLoading(false);
      });
  };

  function _renderAislacionesComponent(slug, name, cantidad, unidad, esComerciable) {
    switch (slug) {
      case "aislacion-termica":
        return (
          <CategoriaAgrupadaResultadoComponent
            name="Aislación térmica"
            cantidadA1={
              dataResults[0].resultadosSteelFrame.aislaciones.filter(
                (p) => p.slugMaterial === "aislacion-termica-paredes-exteriores"
              )[0].totalMetrosCuadrados
            }
            nameA1={
              dataResults[0].resultadosSteelFrame.aislaciones.filter(
                (p) => p.slugMaterial === "aislacion-termica-paredes-exteriores"
              )[0].nombreMaterial
            }
            unidadA1="metros cuadrados"
            nameA2={
              dataResults[0].resultadosSteelFrame.aislaciones.filter(
                (p) => p.slugMaterial === "aislacion-terminca-cielorrasos"
              )[0].nombreMaterial
            }
            cantidadA2={
              dataResults[0].resultadosSteelFrame.aislaciones.filter(
                (p) => p.slugMaterial === "aislacion-terminca-cielorrasos"
              )[0].totalMetrosCuadrados
            }
            unidadA2="metros cuadrados"
            total={
              dataResults[0].resultadosSteelFrame.aislaciones.filter(
                (p) => p.slugMaterial === "aislacion-termica"
              )[0].totalMetrosCuadrados
            }
            totalUnidad="metros cuadrados"
            esComerciable={esComerciable}
          />
        );
      case "aislacion-termica-paredes-exteriores":
        return <div />;

      case "aislacion-terminca-cielorrasos":
        return <div />;

      case "barrera-vapor":
        return (
          <CategoriaAgrupadaResultadoComponent
            name="Barrera de Vapor"
            cantidadA1={
              dataResults[0].resultadosSteelFrame.aislaciones.filter(
                (p) => p.slugMaterial === "barrera-vapor-paredes-exteriores"
              )[0].totalMetrosCuadrados
            }
            nameA1={
              dataResults[0].resultadosSteelFrame.aislaciones.filter(
                (p) => p.slugMaterial === "barrera-vapor-paredes-exteriores"
              )[0].nombreMaterial
            }
            unidadA1="metros cuadrados"
            nameA2={
              dataResults[0].resultadosSteelFrame.aislaciones.filter(
                (p) => p.slugMaterial === "barrera-vapor-techos"
              )[0].nombreMaterial
            }
            cantidadA2={
              dataResults[0].resultadosSteelFrame.aislaciones.filter(
                (p) => p.slugMaterial === "barrera-vapor-techos"
              )[0].totalMetrosCuadrados
            }
            unidadA2="metros cuadrados"
            total={
              dataResults[0].resultadosSteelFrame.aislaciones.filter(
                (p) => p.slugMaterial === "barrera-vapor"
              )[0].totalMetrosCuadrados
            }
            totalUnidad="metros cuadrados"
            esComerciable={esComerciable}
          />
        );
      case "barrera-vapor-paredes-exteriores":
        return <div />;

      case "barrera-vapor-techos":
        return <div />;

      default:
        return (
          <MaterialResultadoComponent
            name={name}
            cantidad={cantidad}
            unidad={unidad}
            otraCantidad={"NA"}
            otraUnidad={"NA"}
            esComerciable={esComerciable}
          />
        );
    }
  }

  function _renderDiagramasRigiComponent(slug, name, cantidad, unidad, esComerciable) {
    switch (slug) {
      case "placas-paneles-y-cubierta-inclinada":
        return (
          <CategoriaAgrupadaResultadoComponent
            name="Placas para paneles y cubierta inclinada"
            cantidadA1={
              dataResults[0].resultadosSteelFrame.diafragmasRigidizacion.filter(
                (p) => p.slugMaterial === "placas-pex"
              )[0].totalMetrosCuadrados
            }
            nameA1={
              dataResults[0].resultadosSteelFrame.diafragmasRigidizacion.filter(
                (p) => p.slugMaterial === "placas-pex"
              )[0].nombreMaterial
            }
            unidadA1="metros cuadrados"
            nameA2={
              dataResults[0].resultadosSteelFrame.diafragmasRigidizacion.filter(
                (p) => p.slugMaterial === "placas-cubierta-inclinada"
              )[0].nombreMaterial
            }
            cantidadA2={
              dataResults[0].resultadosSteelFrame.diafragmasRigidizacion.filter(
                (p) => p.slugMaterial === "placas-cubierta-inclinada"
              )[0].totalMetrosCuadrados
            }
            unidadA2="metros cuadrados"
            total={
              dataResults[0].resultadosSteelFrame.diafragmasRigidizacion.filter(
                (p) => p.slugMaterial === "placas-paneles-y-cubierta-inclinada"
              )[0].totalMetrosCuadrados
            }
            totalUnidad="metros cuadrados"
            esComerciable={esComerciable}
          />
        );
      case "placas-pex":
        return <div />;

      case "placas-cubierta-inclinada":
        return <div />;

      case "placas-entrepisos-y-cubierta-plana":
        return (
          <CategoriaAgrupadaResultadoComponent
            name="Placas para entrepisos y cubierta plana"
            cantidadA1={
              dataResults[0].resultadosSteelFrame.diafragmasRigidizacion.filter(
                (p) => p.slugMaterial === "placas-cubierta-humeda"
              )[0].totalMetrosCuadrados
            }
            nameA1={
              dataResults[0].resultadosSteelFrame.diafragmasRigidizacion.filter(
                (p) => p.slugMaterial === "placas-cubierta-humeda"
              )[0].nombreMaterial
            }
            unidadA1="metros cuadrados"
            nameA2={
              dataResults[0].resultadosSteelFrame.diafragmasRigidizacion.filter(
                (p) => p.slugMaterial === "placas-entrepiso"
              )[0].nombreMaterial
            }
            cantidadA2={
              dataResults[0].resultadosSteelFrame.diafragmasRigidizacion.filter(
                (p) => p.slugMaterial === "placas-entrepiso"
              )[0].totalMetrosCuadrados
            }
            unidadA2="metros cuadrados"
            total={
              dataResults[0].resultadosSteelFrame.diafragmasRigidizacion.filter(
                (p) => p.slugMaterial === "placas-entrepisos-y-cubierta-plana"
              )[0].totalMetrosCuadrados
            }
            totalUnidad="metros cuadrados"
            esComerciable={esComerciable}
          />
        );
      case "placas-cubierta-humeda":
        return <div />;

      case "placas-entrepiso":
        return <div />;

      default:
        return (
          <MaterialResultadoComponent
            name={name}
            cantidad={cantidad}
            unidad={unidad}
            otraCantidad={"NA"}
            otraUnidad={"NA"}
            esComerciable={esComerciable}
          />
        );
    }
  }

  return (
    <div className={classes.root}>
      {messageError === "" ? (
        <div className={classes.containerResultPage}>
          <Typography className={classes.titleResultPage}>
            Calculador de Materiales
          </Typography>
          <div className={classes.divider}>
            <Divider />
          </div>
          <Grid container spacing={4} className={classes.sectionSubtitle}>
            <Grid item xs={6}>
              <Typography className={classes.subtitle}>
                Estas son tus soluciones
              </Typography>
            </Grid>
            <Grid className={classes.buttonBackInit} item xs={6}>
              <ButtonBack
                onClick={() => redirectToHome()}
                color="default"
                variant="outlined"
                className={classes.button}
              >
                Reiniciar búsqueda
              </ButtonBack>
            </Grid>
          </Grid>
          {loading ? (
            <LoadingPage />
          ) : (
            <div className={classes.sectionFormResult}>
              {dataResults &&
                dataResults.map((data, index) => {
                  return (
                    <div key={index}>
                      <Grid className={classes.sectionObra}>
                        <div className={classes.nombreObra}>
                          <NombreObraComponent
                            name={inputs[0].steelframe.plantaBaja.nombre}
                          />
                        </div>

                        <div className={classes.agrupacion}>
                          <AgrupacionComponent agrupacion={"Perfiles PGC"} />
                        </div>
                        {data.resultadosSteelFrame.perfilesPgc.map((p, i) => {
                          return (
                            <div key={i} className={classes.resultadoMaterial}>
                              <PerfilesSFResultadoComponent
                                name={p.nombreMaterial}
                                cantidad={p.subtotalMl}
                                unidad={"metros lineales"}
                                segundaCantidad={p.subtotalUnidad}
                                segundaUnidad={"unidades"}
                                terceraCantidad={p.totalKg}
                                terceraUnidad={"Kilogramos"}
                                esComerciable={p.esComerciable}
                              />
                            </div>
                          );
                        })}

                        <div className={classes.agrupacion}>
                          <AgrupacionComponent agrupacion={"Perfiles PGU"} />
                        </div>
                        {data.resultadosSteelFrame.perfilesPgu.map((p, i) => {
                          return (
                            <div key={i} className={classes.resultadoMaterial}>
                              <PerfilesSFResultadoComponent
                                name={p.nombreMaterial}
                                cantidad={p.subtotalMl}
                                unidad={"metros lineales"}
                                segundaCantidad={p.subtotalUnidad}
                                segundaUnidad={"unidades"}
                                terceraCantidad={p.totalKg}
                                terceraUnidad={"Kilogramos"}
                                esComerciable={p.esComerciable}
                              />
                            </div>
                          );
                        })}

                        <div className={classes.agrupacion}>
                          <AgrupacionComponent agrupacion={"Flejes"} />
                        </div>
                        {data.resultadosSteelFrame.flejes.map((p, i) => {
                          return (
                            <div key={i} className={classes.resultadoMaterial}>
                              <MaterialResultadoComponent
                                name={p.nombreMaterial}
                                cantidad={p.metrosLineales}
                                unidad={"metros"}
                                otraCantidad={p.totalKg}
                                otraUnidad={"Kilogramos"}
                                esComerciable={p.esComerciable}
                              />
                            </div>
                          );
                        })}

                        <div className={classes.agrupacion}>
                          <AgrupacionComponent agrupacion={"Anclajes"} />
                        </div>
                        {data.resultadosSteelFrame.anclajes.map((p, i) => {
                          return (
                            <div key={i} className={classes.resultadoMaterial}>
                              <MaterialResultadoComponent
                                name={p.nombreMaterial}
                                cantidad={p.totalUnidad}
                                unidad={"unidades"}
                                otraCantidad={"NA"}
                                otraUnidad={"NA"}
                                esComerciable={p.esComerciable}
                              />
                            </div>
                          );
                        })}

                        <div className={classes.agrupacion}>
                          <AgrupacionComponent
                            agrupacion={"Tornillos autoperforantes"}
                          />
                        </div>
                        {data.resultadosSteelFrame.tornillosAutoperformantes.map(
                          (p, i) => {
                            return (
                              <div
                                key={i}
                                className={classes.resultadoMaterial}
                              >
                                <MaterialResultadoComponent
                                  name={p.nombreMaterial}
                                  cantidad={p.totalUnidad}
                                  unidad={"unidades"}
                                  otraCantidad={"NA"}
                                  otraUnidad={"NA"}
                                  esComerciable={p.esComerciable}
                                />
                              </div>
                            );
                          }
                        )}

                        <div className={classes.agrupacion}>
                          <AgrupacionComponent agrupacion={"Ángulos"} />
                        </div>
                        {data.resultadosSteelFrame.angulos.map((p, i) => {
                          return (
                            <div key={i} className={classes.resultadoMaterial}>
                              <MaterialResultadoComponent
                                name={p.nombreMaterial}
                                cantidad={p.totalUnidad}
                                unidad={"unidades"}
                                otraCantidad={"NA"}
                                otraUnidad={"NA"}
                                esComerciable={p.esComerciable}
                              />
                            </div>
                          );
                        })}

                        <div className={classes.agrupacion}>
                          <AgrupacionComponent agrupacion={"Aislaciones"} />
                        </div>
                        {data.resultadosSteelFrame.aislaciones.map((p, i) => {
                          return (
                            <div key={i} className={classes.resultadoMaterial}>
                              {_renderAislacionesComponent(
                                p.slugMaterial,
                                p.nombreMaterial,
                                p.slugMaterial ===
                                  "aislacion-hidraulica-y-acustica-perimentral-en-pb-bajo-solera" ||
                                  p.slugMaterial ===
                                    "aislacion-acustica-perfiles-ep-cubierta-humeda"
                                  ? p.totalMetrosLineales
                                  : p.slugMaterial ===
                                      "banda-flexible-aislacion-vanos" ||
                                    p.slugMaterial ===
                                      "cinta-solape-de-agua-y-viento"
                                  ? p.totalUnidad
                                  : p.totalMetrosCuadrados,
                                p.slugMaterial ===
                                  "aislacion-hidraulica-y-acustica-perimentral-en-pb-bajo-solera" ||
                                  p.slugMaterial ===
                                    "aislacion-acustica-perfiles-ep-cubierta-humeda"
                                  ? "metros"
                                  : p.slugMaterial ===
                                      "banda-flexible-aislacion-vanos" ||
                                    p.slugMaterial ===
                                      "cinta-solape-de-agua-y-viento"
                                  ? "unidades"
                                  : "metros cuadrados",
                                  p.esComerciable
                              )}
                            </div>
                          );
                        })}

                        <div className={classes.agrupacion}>
                          <AgrupacionComponent
                            agrupacion={"Diafragma de rigidización"}
                          />
                        </div>
                        {data.resultadosSteelFrame.diafragmasRigidizacion.map(
                          (p, i) => {
                            return (
                              <div
                                key={i}
                                className={classes.resultadoMaterial}
                              >
                                {_renderDiagramasRigiComponent(
                                  p.slugMaterial,
                                  p.nombreMaterial,
                                  p.totalMetrosCuadrados,
                                  "metros cuadrados",
                                  p.esComerciable
                                )}
                              </div>
                            );
                          }
                        )}

                        <div className={classes.agrupacion}>
                          <AgrupacionComponent
                            agrupacion={"Placas de roca yeso"}
                          />
                        </div>
                        {data.resultadosSteelFrame.placasRocaYeso.map(
                          (p, i) => {
                            return (
                              <div
                                key={i}
                                className={classes.resultadoMaterial}
                              >
                                <MaterialResultadoComponent
                                  name={p.nombreMaterial}
                                  cantidad={p.totalMetrosCuadrados}
                                  unidad={"metros cuadrados"}
                                  otraCantidad={"NA"}
                                  otraUnidad={"NA"}
                                  esComerciable={p.esComerciable}
                                />
                              </div>
                            );
                          }
                        )}

                        <div className={classes.agrupacion}>
                          <AgrupacionComponent
                            agrupacion={"Terminación exterior"}
                          />
                        </div>
                        {data.resultadosSteelFrame.terminacionExterior.map(
                          (p, i) => {
                            return (
                              <div
                                key={i}
                                className={classes.resultadoMaterial}
                              >
                                <MaterialResultadoComponent
                                  name={p.nombreMaterial}
                                  cantidad={p.totalMetrosCuadrados}
                                  unidad={p.slugMaterial === "omega-pgo-37-0-9-colocacion-cubierta" ? "metros" : "metros cuadrados"}
                                  otraCantidad={"NA"}
                                  otraUnidad={"NA"}
                                  esComerciable={p.esComerciable}
                                />
                              </div>
                            );
                          }
                        )}
                      </Grid>
                      <div className={classes.referencia}>(*) Estos artículos NO son Comercializados por Barbieri a través de la Tienda Digital</div>
                      <div className={classes.dividers}>
                        <Divider />
                      </div>
                      <Grid
                        container
                        className={classes.containerButtons}
                        spacing={0}
                      >
                        <Grid
                          className={classes.buttonBack}
                          item
                          xs={12}
                          md={4}
                        >
                          <ButtonBack
                            onClick={() => redirectToBack()}
                            color="default"
                            variant="outlined"
                            className={classes.button}
                          >
                            Atrás
                          </ButtonBack>
                        </Grid>
                        <Grid
                          className={classes.buttonDownload}
                          item
                          xs={12}
                          md={4}
                        >
                          <ButtonDownload
                            onClick={() => descargarExcel()}
                            color="default"
                            variant="outlined"
                            className={classes.button}
                          >
                            Descargar
                          </ButtonDownload>
                        </Grid>
                        <Grid className={classes.buttonBuy} item xs={12} md={4}>
                          <ButtonBuy
                            type="submit"
                            value="Submit"
                            color="secondary"
                            variant="contained"
                            onClick={() => comprarMateriales()}
                            className={classes.button}
                          >
                            Comprar
                          </ButtonBuy>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      ) : (
        <DescriptionAlerts
          severity="error"
          title="Error"
          message={messageError}
        />
      )}
    </div>
  );
}

export default ResultadoSteelFramePage;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 5,
    overflow: "hidden",
    paddingLeft: "15%",
    paddingRight: "15%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "3%",
      paddingRight: "3%",
    },
  },
  referencia: {
    fontSize: "17px",
    fontWeight: "bold",
    color: "#E2231A",
    paddingTop: 5
  },
  titleResultPage: {
    fontSize: "30px",
    fontWeight: "bold",
    color: "#022A3A",
  },
  sectionSubtitle: {
    paddingTop: 20,
    paddingbottom: 20,
  },
  subtitle: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "black",
  },
  containerResultPage: {
    position: "relative",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  dividers: {
    paddingTop: 20,
    paddingBottom: 20,
  },

  buttonBackInit: {
    textAlign: "end",
  },
  sectionObra: {
    paddingTop: 30,
    paddingBottom: 10,
  },
  nombreObra: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  agrupacion: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  resultadoMaterial: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  buttonBack: {
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      paddingBottom: 10,
    },
  },
  buttonBuy: {
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      paddingBottom: 10,
    },
  },
  buttonDownload: {
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      paddingBottom: 10,
    },
  },
  containerButtons: {
    paddingTop: 50,
    paddingBottom: 50,
  },
}));

const ButtonBuy = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "white",
    backgroundColor: "#022A3A",
    borderColor: "#022A3A",
    "&:hover": {
      backgroundColor: "#022a44",
      borderColor: "#022a44",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#022a44",
      borderColor: "#022a44",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(2,42,58,.5)",
    },
  },
})(Button);

const ButtonBack = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "#5B6670",
    backgroundColor: "transparent",
    borderColor: "#5B6670",
    "&:hover": {
      backgroundColor: "rgba(91,102,112,.1)",
      borderColor: "#5B6670",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "transparent",
      borderColor: "#5B6670",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(91,102,112,.5)",
    },
  },
})(Button);

const ButtonDownload = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "black",
    backgroundColor: "transparent",
    borderColor: "black",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.2)",
      borderColor: "black",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "transparent",
      borderColor: "black",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,0,0,.1)",
    },
  },
})(Button);
