import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css'

import { Provider } from "react-redux";
import configureStore, { history } from "./redux/store";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import theme from "./utils/theme";

import './custom.scss';

import { AppContainer } from 'react-hot-loader';

import App from './App'


const store = configureStore();

const render = () => {
  ReactDOM.render(
    <AppContainer>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App history={history} />
      </Provider>
      </ThemeProvider>
    </AppContainer>,
    document.getElementById("root")
  )
}

render()

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./App', () => {
    render()
  })
}