import React from "react";

export const CheckBoxComponent = (props) => {
  return (
    <li className="liCheckbox">
      <div className="checkboxes">
        <label className="checkbox">
          <input
            key={props.id}
            onChange={props.handleCheckChieldElement}
            type="checkbox"
            checked={props.isChecked}
            value={props.slug}
          />{" "}
          <span className="labelCheck">{props.nombre}</span>
        </label>
      </div>
    </li>
  );
};

export default CheckBoxComponent;
