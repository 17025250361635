import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialResultadoComponent from "../Resultados/MaterialResultadoComponent";
import ReferenciaComponent from "../Resultados/ReferenciaComponent";

export default function FormResultPvcRevestimientoDecorativo(props) {
  const classes = useStyles();
  const { resultado } = props;
  const [campos, setCampos] = React.useState([]);

  useEffect(() => {
    if (campos.length === 0) {
      setCampos(resultado);
    }
  });

  return (
    <div className="container">
      {campos &&
        campos.map((output, index) => {
          return (
            <div key={index} className={classes.resultadoMaterial}>
              <MaterialResultadoComponent
                name={output.nombre}
                cantidad={output.unidades}
                unidad={"unidades"}
                otraCantidad={"NA"}
                otraUnidad={"NA"}
                esComerciable={output.esComerciable}
              />
            </div>
          );
        })}
      <div className={classes.sectionReferences}>
        <ReferenciaComponent
          texto="Las tablas de Revestimiento Decorativo se fijarán con adhesivo a la pared existente dependiendo el sustrato"
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 5,
    overflow: "hidden",
    padding: theme.spacing(0, 35),
  },
  titleResultPage: {
    fontSize: "30px",
    fontWeight: "bold",
    color: "#022A3A",
  },
  sectionSubtitle: {
    paddingTop: 20,
    paddingbottom: 20,
  },
  subtitle: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "black",
  },
  containerResultPage: {
    position: "relative",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(8),
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  buttonBackInit: {
    textAlign: "end",
  },
  sectionObra: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  nombreObra: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  agrupacion: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  resultadoMaterial: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  sectionReferences: {
    paddingTop: 20,
    paddingBottom: 10,
  },
}));
