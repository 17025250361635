import React, { Component } from 'react'
import { Row } from 'react-bootstrap';


export default class Header extends Component {
    
    render() {
        return (
            <div className="header">
                <Row>
                    <div className="col-md-12">
                        <div className="container-large">
                            <div className="widget-type-logo float-sm-left">
                                <a href="https://www.adbarbieri.com/" target="_blank">
                                    <img className="logo" src={require('../assets/images/logo.png')} alt="Logo" />
                                </a>     
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
        )
    }
}

