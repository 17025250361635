import React, { useEffect } from "react";
import "./style.module.scss";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import NombreObraComponent from "../../components/Resultados/NombreObraComponent";
import FormResultDeck from "../../components/FormsResults/FormResultDeck";
import { USER_DATA_URI, HOME_URI } from "../../routes";
import calcularService from "../../components/Services/calcularService";
import descargarExcelService from "../../components/Services/descargarExcelService";
import LoadingPage from "../../components/LoadingPage";
import DescriptionAlerts from "../../components/DescriptionAlerts";
import FormResultDrywallPared from "../../components/FormsResults/FormResultDrywallPared";
import FormResultDrywallCielorraso from "../../components/FormsResults/FormResultDrywallCielorraso";
import FormResultPvcCielorraso from "../../components/FormsResults/FormResultPvcCielorraso";
import FormResultPvcRevestimientoDecorativo from "../../components/FormsResults/FormResultPvcRevestimientoDecorativo";
import FormResultSuspensionesTx from "../../components/FormsResults/FormResultSuspensionesTx";
import FormResultZocalo from "../../components/FormsResults/FormResultZocalo";
import comprarMaterialesMagento from "../../components/Services/comprarMaterialesMagento";

export const DATA_INPUT = "data_input";
export const DATA_USER = "data_user";
export const SISTEMAS_SELECCIONADOS = "sistemas";

export default function ResultadoPage(props) {
  const classes = useStyles();
  const [dataResults, setDataResults] = React.useState([]);
  let [loading, setLoading] = React.useState(true);
  const [messageError, setMessageError] = React.useState("");
  const [urlCompra, setUrlCompra] = React.useState("");

  function _renderFormContent(sistema, resultado) {
    switch (sistema) {
      case "deck":
        return <FormResultDeck resultado={resultado} />;
      case "drywall-pared":
        return <FormResultDrywallPared resultado={resultado} />;
      case "drywall-cielorraso":
        return <FormResultDrywallCielorraso resultado={resultado} />;
      case "pvc-cielorrasos":
        return <FormResultPvcCielorraso resultado={resultado} />;
      case "pvc-revestimiento-dec":
        return <FormResultPvcRevestimientoDecorativo resultado={resultado} />;
      case "suspensiones-tx":
        return <FormResultSuspensionesTx resultado={resultado} />;
      case "zocalos-pvc":
        return <FormResultZocalo resultado={resultado} />;
      default:
        return <div>Not Found</div>;
    }
  }

  useEffect(() => {
    if (dataResults.length === 0) {
      calcularService(datainputs)
        .then((response) => {
          let respuesta = response;
          respuesta.forEach((element) => 
            element.SistemaConstruccionSeleccionadoId= datainputs.find((res) => res.slug == element.slug).sistemaConstruccionSeleccionadoId
          );
          respuesta.map((r) => {
            let json = _armarArray(r.resultados, r.slug);
            r.resultados = json;
          });
          setDataResults(respuesta);
          setLoading(false);
        })
        .catch(function (error) {
          setMessageError(
            "Disculpe las molestias ocasionadas. Estamos trabajando para reestablecer la plataforma."
          );
          setLoading(false);
        });
    }

  });

  function _armarArray(resultadoSistema, slug) {
    let input = _getInput(slug);
    let aux = [];
    let i = 0;

    if (slug === "suspensiones-tx") {
      resultadoSistema.map((r) => {
        aux[i] = {
          tipoPerfil: input[i].tipoPerfil,
          distanciaPerfil: input[i].distanciaPerfil,
          grillasPlacas: input[i].grillasPlacas,
          nombreCalculo: input[i].nombre,
          materiales: r.materiales,
        };
        i++;
      });
    } else {
      resultadoSistema.map((r) => {
        aux[i] = { nombreCalculo: input[i].nombre, materiales: r.materiales };
        i++;
      });
    }

    return aux;
  }

  function _getInput(slug) {
    let a = datainputs;
    let result = [];

    a.map((input) => {
      if (input.slug === slug) {
        result = input.inputs;
      }
    });

    return result;
  }

  function _getData() {
    let aux = JSON.parse(sessionStorage.getItem(DATA_INPUT)).map(function (
      input
    ) {
      return {
        id: input.id,
        nombre: input.nombre,
        slug: input.slug,
        sistemaConstruccionSeleccionadoId: input.SistemaConstruccionSeleccionadoId,
        camposRequeridos: input.camposRequeridos,
      };
    });

    aux.map((input) => {
      if (input.slug === "zocalos-pvc") {
        let camposRequeridos = input.camposRequeridos;

        camposRequeridos &&
          camposRequeridos.map((cr, i) => {
            if (cr.tipoCalculo === "por-largo-paredes") {
              cr.largo = 0;
              cr.ancho = 0;
            }

            let auxCR = [];

            cr.medidas.length > 0 &&
              cr.medidas.map((c) => {
                if (c.lado === "") {
                  auxCR.push(0);
                } else {
                  auxCR.push(parseFloat(c.lado));
                }
              });
            cr.medidas = auxCR;
          });
      }
    });

    return aux;
  }

  const datainputs = _getData().map(function (input) {
    return {
      id: input.id,
      nombre: input.nombre,
      slug: input.slug,
      sistemaConstruccionSeleccionadoId: input.sistemaConstruccionSeleccionadoId,
      inputs: input.camposRequeridos,
    };
  });

  const redirectToBack = () => {
    sessionStorage.removeItem(DATA_INPUT);
    sessionStorage.removeItem(DATA_USER);
    sessionStorage.removeItem(SISTEMAS_SELECCIONADOS);
    props.history.push(USER_DATA_URI);
  };

  const redirectToHome = () => {
    sessionStorage.removeItem(DATA_INPUT);
    sessionStorage.removeItem(DATA_USER);
    sessionStorage.removeItem(SISTEMAS_SELECCIONADOS);
    props.history.push(HOME_URI);
  };

  function _getDataPrintExcel(sistema) {
    const inputs = datainputs.filter((sis) => sis.slug === sistema);
    const results = dataResults.filter((sis) => sis.slug === sistema);
    
    return {
      id: inputs[0].id,
      nombre: inputs[0].nombre,
      email: JSON.parse(sessionStorage.getItem(DATA_USER)).email,
      nombreCliente: JSON.parse(sessionStorage.getItem(DATA_USER)).nombre,
      slug: inputs[0].slug,
      inputs: inputs[0].inputs,
      resultados: results[0].resultados,
    };
  }

  const descargarExcel = (sistema, nombre) => {
    setLoading(true);
    descargarExcelService(_getDataPrintExcel(sistema), nombre)
      .then((response) => {
        let respuesta = response;
        setLoading(false);
      })
      .catch(function (error) {
        setMessageError(
          "Disculpe las molestias ocasionadas. Estamos trabajando para reestablecer la plataforma."
        );
        setLoading(false);
      });
  };

  function _getNameAtributos(slug) {
    switch (slug) {
      case "cielorraso-suspendido-tx-15-16":
        return "Cielorrasos suspendidos Barbieri TX 15/16”";
      case "cielorraso-suspendido-tx-9-16":
        return "Cielorraso suspendido Barbieri TX 9/16”";
      case "largueros-cada-1-22":
        return "Largueros ubicados cada 1.22 m";
      case "largueros-cada-0-61":
        return "Largueros ubicados cada 0.61 m";
      case "placas-0-61-0-61":
        return "Grillas placas 0.61 x 0.61 m";
      case "placas-0-61-1-22":
        return "Grillas placas 0.61 x 1.22 m";
      default:
        "";
    }
  }

  function _getCampoSelect(slug, i) {
    const inputs = datainputs.filter((sis) => sis.slug === slug);
    console.log(inputs[0].inputs[i].largoTablaPvc)

    if (slug === 'pvc-cielorrasos' && (inputs[0].inputs[i].largoTablasPvc === "4" || inputs[0].inputs[i].largoTablasPvc === "5")){
      return inputs[0].inputs[i].largoTablasPvc === "4" ? 38 : 39;
    }else {
      return inputs[0].inputs[i].color ? inputs[0].inputs[i].color : null;
    }
  }

  function _getMaterialesSKU() {
    let auxiliar = [];

    dataResults.map((res) => {
      let index = 0;
      res.resultados.map((x) => {
        let objeto = {};
        objeto.id_sistema = res.id;
        objeto.campo_select = _getCampoSelect(res.slug, index);
        objeto.materiales = [];
        objeto.SistemaConstruccionSeleccionadoId = res.SistemaConstruccionSeleccionadoId;

        x.materiales.map((mat) => {
          let matAux = {};
          matAux.id_material = mat.id;
          console.log(mat)
          if (res.slug === "deck") {
            matAux.cantidad = mat.unidades;
          } else if (res.slug == "drywall-cielorraso") {
            matAux.cantidad =
              mat.agrupacion === "Perfiles" ? mat.tiras : mat.unidades;
          } else if (res.slug == "drywall-pared") {

            if (mat.slug === 'banda-acustica-50'){
              matAux.cantidad = Math.ceil(mat.total);
            }else {
              matAux.cantidad =
                mat.agrupacion === "Perfiles" ? mat.tiras : mat.unidades;
            }

          } else if (res.slug == "pvc-cielorrasos") {
            matAux.cantidad =
              mat.agrupacion === "Perfiles" ? mat.tiras : mat.unidades;
          } else if (res.slug == "pvc-revestimiento-dec") {
            matAux.cantidad = mat.unidades;
          } else if (res.slug == "suspensiones-tx") {
            matAux.cantidad =
              mat.agrupacion === "Placas de Cielorraso"
                ? mat.unidades
                : mat.tiras;
          } else if (res.slug == "zocalos-pvc") {
            matAux.cantidad = mat.unidades;
          }

          objeto.materiales.push(matAux);
        });
        index = index + 1;
        auxiliar.push(objeto);
      });
    });

    return auxiliar;
  }

  const comprarMateriales = () => {
    setLoading(true);
    comprarMaterialesMagento(_getMaterialesSKU())
      .then((response) => {
        let respuesta = response;

        if (respuesta.url !== null) {
          openNewTabOrNewWindow(respuesta.url);
          //this.setUrlCompra = this.respuesta.url;
        } else {
          setMessageCarritoError(
            "No se pudieron agregar los productos al carrito de compra. Vuelva a intertarlo nuevamente."
          );
        }

        setLoading(false);
      })
      .catch(function (error) {
        setMessageError(
          "Disculpe las molestias ocasionadas. Estamos trabajando para reestablecer la plataforma."
        );
        setLoading(false);
      });
  };


  function fireClickEvent(element) {
    var evt = new window.MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
    });

    element.dispatchEvent(evt);
  }

  function openNewTabOrNewWindow(targetURL) {
    var a = document.createElement('a');
    a.href = targetURL;

    a.target = '_blank'; // now it will open new tab/window and bypass any popup blocker!

    fireClickEvent(a);
  }

  
  return (
    <div className={classes.root}>
      {messageError === "" ? (
        <div className={classes.containerResultPage}>
          <Typography className={classes.titleResultPage}>
            Calculador de Materiales
          </Typography>
          <div className={classes.divider}>
            <Divider />
          </div>
          <Grid container spacing={4} className={classes.sectionSubtitle}>
            <Grid item xs={6}>
              <Typography className={classes.subtitle}>
                Estas son tus soluciones
              </Typography>
            </Grid>
            <Grid className={classes.buttonBackInit} item xs={6}>
              <ButtonBack
                onClick={() => redirectToHome()}
                color="default"
                variant="outlined"
                className={classes.button}
              >
                Reiniciar búsqueda
              </ButtonBack>
            </Grid>
          </Grid>
          {loading ? (
            <LoadingPage />
          ) : (
            <div className={classes.sectionFormResult}>
              {dataResults &&
                dataResults.map((data, index) => {
                  return (
                    <div key={index}>
                      <Grid className={classes.sectionSistema}>
                        <Typography className={classes.subtitleInputPage}>
                          Sistema de construcción {data.nombre}
                        </Typography>
                      </Grid>
                      {data &&
                        data.resultados.map((resultado, index) => {
                          return (
                            <div key={index} className={classes.formsResult}>
                              <Grid className={classes.sectionObra}>
                                <div className={classes.nombreObra}>
                                  <NombreObraComponent
                                    name={resultado.nombreCalculo}
                                  />
                                </div>
                                <div className={classes.sectionSubtitleSuspensiones}>
                                  {data.slug === "suspensiones-tx" ? (
                                    <Typography
                                      className={classes.subtitleSuspensionesTx}
                                    >
                                      {_getNameAtributos(resultado.distanciaPerfil)} - <span className={classes.placasGrillas}>{_getNameAtributos(resultado.grillasPlacas)}</span>
                                    </Typography>
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              </Grid>
                              {_renderFormContent(
                                data.slug,
                                resultado.materiales
                              )}
                            </div>
                          );
                        })}
                      <div className={classes.referencia}>(*) Estos artículos NO son Comercializados por Barbieri a través de la Tienda Digital</div>
                      <div className={classes.dividers}>
                        <Divider />
                      </div>
                      <Grid
                        container
                        className={classes.containerButtons}
                        spacing={0}
                      >
                        <Grid className={classes.buttonBack} item xs={12} md={4}>
                          <ButtonBack
                            onClick={() => redirectToBack()}
                            color="default"
                            variant="outlined"
                            className={classes.button}
                          >
                            Atrás
                          </ButtonBack>
                        </Grid>
                        <Grid className={classes.buttonDownload} item xs={12} md={4}>
                          <ButtonDownload
                            onClick={() =>
                              descargarExcel(data.slug, data.nombre)
                            }
                            color="default"
                            variant="outlined"
                            className={classes.button}
                          >
                            Descargar
                          </ButtonDownload>
                        </Grid>
                        <Grid className={classes.buttonBuy} item xs={12} md={4}>
                          <ButtonBuy
                            type="submit"
                            value="Submit"
                            color="secondary"
                            variant="contained"
                            onClick={() => comprarMateriales()}
                            className={classes.button}
                          >
                            Comprar
                          </ButtonBuy>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      ) : (
        <DescriptionAlerts
          severity="error"
          title="Error"
          message={messageError}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 5,
    overflow: "hidden",
    paddingLeft: '15%',
    paddingRight: '15%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2%',
      paddingRight: '2%',
    },
  },
  titleResultPage: {
    fontSize: "30px",
    fontWeight: "bold",
    color: "#022A3A",
  },
  referencia: {
    fontSize: "17px",
    fontWeight: "bold",
    color: "#E2231A",
    paddingTop: 5
  },
  sectionSubtitleSuspensiones: {
    paddingTop: 20,
    paddingbottom: 20,
  },
  sectionSubtitle: {
    paddingTop: 20,
    paddingbottom: 20,
  },
  subtitle: {
    fontSize: "22px",
    fontWeight: "bold",
    color: "black",
  },
  subtitleSuspensionesTx: {
    fontSize: "22px",
    fontWeight: "400",
    color: "black",
  },
  placasGrillas: {
    fontSize: "22px",
    fontWeight: "500",
    color: "black",
  },
  subtitleInputPage: {
    fontSize: "23px",
    fontWeight: "bold",
    color: "#022A3A",
  },
  sectionFormResult: {
    paddingTop: 60,
    paddingBottom: 30,
  },
  containerResultPage: {
    position: "relative",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(8),
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  dividers: {
    paddingTop: 20,
    paddingBottom: 20,
  },

  buttonBackInit: {
    textAlign: "end",
  },
  sectionObra: {
    paddingTop: 30,
    paddingBottom: 10,
  },
  nombreObra: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  agrupacion: {
    paddingTop: 30,
    paddingBottom: 20,
  },
  resultadoMaterial: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  buttonBack: {
    textAlign: "right",
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
      paddingBottom: 10,
    },
  },
  buttonBuy: {
    textAlign: "left",
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
      paddingBottom: 10,
    },
  },
  buttonDownload: {
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
      paddingBottom: 10,
    },
  },
  containerButtons: {
    paddingTop: 50,
    paddingBottom: 50,
  },
}));

const ButtonBuy = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "white",
    backgroundColor: "#022A3A",
    borderColor: "#022A3A",
    "&:hover": {
      backgroundColor: "#022a44",
      borderColor: "#022a44",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#022a44",
      borderColor: "#022a44",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(2,42,58,.5)",
    },
  },
})(Button);

const ButtonBack = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "#5B6670",
    backgroundColor: "transparent",
    borderColor: "#5B6670",
    "&:hover": {
      backgroundColor: "rgba(91,102,112,.1)",
      borderColor: "#5B6670",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "transparent",
      borderColor: "#5B6670",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(91,102,112,.5)",
    },
  },
})(Button);

const ButtonDownload = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "black",
    backgroundColor: "transparent",
    borderColor: "black",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.2)",
      borderColor: "black",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "transparent",
      borderColor: "black",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,0,0,.1)",
    },
  },
})(Button);
