import React from 'react';
import { ConnectedRouter } from 'connected-react-router'
import routes from './routes'
import Layout from './components/Layout'



const App = ({ history }) => {

  return (
    <ConnectedRouter history={history}>
      <Layout>
        {routes} 
      </Layout>
    </ConnectedRouter>
  )
}

export default App




