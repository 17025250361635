import React, { useEffect } from "react";
import InputBase from "@material-ui/core/InputBase";
import Select from "@material-ui/core/Select";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import HelpIcon from "@material-ui/icons/Help";

export default function FormZocaloLargoAmbiente(props) {
  const classes = useStyles();
  const {
    fields,
    handleInputChange,
    sistema,
    index,
    colors,
    tiposZocalos,
  } = props;
  const [reference, setReference] = React.useState("");

  return (
    <div>
      <Grid container spacing={5} className={classes.gridMain}>
        <Grid className={classes.gridSectionFields} item xs={6}>
          <Grid className={classes.gridLabelFirst} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Lado mayor del
              ambiente (m)
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.largo}
                onChange={(e) => handleInputChange(e, sistema, index)}
                name="largo"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step: "0.01",
                    min: "0",
                    max: "9999",
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("largo")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Lado menor del
              ambiente (m)
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.ancho}
                onChange={(e) => handleInputChange(e, sistema, index)}
                name="ancho"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step: "0.01",
                    min: "0",
                    max: "9999",
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("ancho")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              Desperdicio sugerido 5%
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.desperdicio}
                onChange={(e) => handleInputChange(e, sistema, index)}
                name="desperdicio"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    min: "0",
                    max: "100",
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}></Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Tipo de zócalo
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <Select
                native
                className={classes.autocomplete}
                value={fields.tipoZocalo}
                onChange={(e) => handleInputChange(e, sistema, index)}
                required
                input={<DesignInput />}
                fullWidth
                inputProps={{
                  name: "tipoZocalo",
                  id: "outlined-tipo-zocalo",
                }}
              >
                <option value="">Seleccionar</option>
                {tiposZocalos &&
                  tiposZocalos.map((tipo, i) => (
                    <option key={i} value={tipo.slug}>
                      {tipo.valor}
                    </option>
                  ))}
              </Select>
            </Grid>

            <Grid className={classes.gridReference} item xs={2}></Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Color
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              {fields.tipoZocalo === "zocalo-sanitario-pvc" || fields.tipoZocalo === "zocalo-minimal-nuevo"  ? (
                <Select
                  native
                  className={classes.autocomplete}
                  value={fields.color}
                  onChange={(e) => handleInputChange(e, sistema, index)}
                  required
                  input={<DesignInput />}
                  fullWidth
                  inputProps={{
                    name: "color",
                    id: "outlined-color",
                  }}
                >
                  <option value="">Seleccionar</option>
                  <option value="28">Blanco</option>
                </Select>
              ) : (
                <Select
                  native
                  className={classes.autocomplete}
                  value={fields.color}
                  onChange={(e) => handleInputChange(e, sistema, index)}
                  required
                  input={<DesignInput />}
                  fullWidth
                  inputProps={{
                    name: "color",
                    id: "outlined-color",
                  }}
                >
                  <option value="">Seleccionar</option>
                  {colors &&
                    colors.map((color, i) => (
                      <option key={i} value={color.id}>
                        {color.valor}
                      </option>
                    ))}
                </Select>
              )}
            </Grid>

            <Grid className={classes.gridReference} item xs={2}></Grid>
          </Grid>
        </Grid>
        <Grid className={classes.gridSectionImages} item xs={6}>
          <div className={classes.imageReference}>
            <div className={classes.imgLargo}>
              {reference === "largo" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/zocalo/largo-mayor.jpg")}
                  alt="Largo Mayor del Ambiente"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgAncho}>
              {reference === "ancho" ? (
                <img
                  width="100%"
                  height="auto"
                  src={require("../../../assets/images/zocalo/largo-menor.jpg")}
                  alt="Largo Menor del ambiente"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: "400",
    color: "black",
    paddingBottom: 10,
  },
  referencia: {
    fontSize: "16px",
    fontWeight: "400",
    color: "black",
  },
  required: {
    color: "red",
  },
  field: {
    paddingTop: 6,
  },
  iconHelp: {
    color: "#5B6670",
    marginTop: 20,
  },
  gridMain: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  gridReferencia: {
    paddingTop: 30,
  },
  gridInputField: {
    paddingBottom: 5,
  },
  gridLabel: {
    paddingTop: 20,
  },
  gridSectionImages: {
    padding: 20,
  },
  imageReference: {
    textAlign: "center",
  },
  imgLargo: {
    position: "relative",
    paddingTop: 10,
  },
  imgAncho: {
    position: "relative",
    paddingTop: 20,
  },
}));

const DesignInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "18px 26px 18px 12px",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
