import React, { useEffect } from "react";
import getCampoOfSystem from "../Services/getCampoOfSystem";
import InputBase from "@material-ui/core/InputBase";
import Select from "@material-ui/core/Select";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import HelpIcon from "@material-ui/icons/Help";
import EditIcon from '@material-ui/icons/Edit';

export default function FormDrywallPared(props) {
  const classes = useStyles();

  const { fields, handleInputChange, sistema, idSistema, index } = props;
  const [modulaciones, setModulacion] = React.useState([]);
  const [reference, setReference] = React.useState("");

  useEffect(() => {
    if (modulaciones.length === 0) {
      getCampoOfSystem(idSistema, "modulacion")
        .then((response) => {
          setModulacion(response);
        })
        .catch(function (error) {
          console.log("Error al cargar los valores de modulacion");
        });
    }
  }, [setModulacion]);

  return (
    <div>
      <div className="row">
        <div className="column icon">
        <EditIcon className={classes.iconEdit}/>
        </div>
        <div className="column name-calculo">
          <input
            type="text"
            required
            name="nombre"
            maxLength="31"
            value={fields.nombre}
            onChange={(e) => handleInputChange(e, sistema, index)}
          />
          <Typography className={classes.textEdit}>
            Puede editar el nombre del cálculo
          </Typography>
        </div>
        
      </div>
      <Grid container spacing={5} className={classes.gridMain}>
        <Grid className={classes.gridSectionFields} item xs={6}>
          <Grid className={classes.gridLabelFirst} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Largo tabique (m)
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.largoTabique}
                onChange={(e) => handleInputChange(e, sistema, index)}
                name="largoTabique"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step: "0.01",
                    min: "0",
                    max: "9999",
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("largo")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Altura (m)
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.altura}
                onChange={(e) => handleInputChange(e, sistema, index)}
                name="altura"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    step: "0.01",
                    min: "0",
                    max: "4",
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("altura")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              <span className={classes.required}>*</span> Modulación (m)
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <Select
                native
                className={classes.autocomplete}
                value={fields.modulacion}
                onChange={(e) => handleInputChange(e, sistema, index)}
                required
                input={<DesignInput />}
                fullWidth
                inputProps={{
                  name: "modulacion",
                  id: "outlined-modulacion",
                }}
              >
                <option value="">Seleccionar</option>
                {modulaciones &&
                  modulaciones.map((modulacion, i) => (
                    <option key={i} value={modulacion.valor}>
                      {modulacion.valor}
                    </option>
                  ))}
              </Select>
            </Grid>

            <Grid className={classes.gridReference} item xs={2}>
              <HelpIcon
                onMouseOver={() => setReference("modulacion")}
                onMouseOut={() => setReference("")}
                className={classes.iconHelp}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridLabel} item xs={12}>
            <Typography className={classes.label}>
              Desperdicio sugerido 5%
            </Typography>
          </Grid>
          <Grid container spacing={3} className={classes.gridInputField}>
            <Grid className={classes.gridField} item xs={10}>
              <TextField
                placeholder="0.00 m"
                fullWidth
                margin="none"
                className={classes.field}
                value={fields.desperdicio}
                onChange={(e) => handleInputChange(e, sistema, index)}
                name="desperdicio"
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  required: true,
                  type: "number",
                  inputProps: {
                    min: "0",
                    max: "100",
                  },
                }}
                variant="outlined"
              />
            </Grid>

            <Grid className={classes.gridReference} item xs={2}></Grid>
          </Grid>
        </Grid>
        <Grid className={classes.gridSectionImages} item xs={6}>
          <div className={classes.imageReference}>
            <div className={classes.imgLargo}>
              {reference === "largo" ? (
                <img
                  width="80%"
                  height="auto"
                  src={require("../../assets/images/drywall-pared/largo.jpg")}
                  alt="Largo Drywall pared"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgAltura}>
              {reference === "altura" ? (
                <img
                  width="80%"
                  height="auto"
                  src={require("../../assets/images/drywall-pared/altura.jpg")}
                  alt="Altura Drywall pared"
                />
              ) : (
                ""
              )}
            </div>
            <div className={classes.imgModulacion}>
              {reference === "modulacion" ? (
                <img
                  width="80%"
                  height="auto"
                  src={require("../../assets/images/drywall-cielorraso/modulacion.jpg")}
                  alt="Modulación Drywall cielorraso"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "18px",
    fontWeight: "400",
    color: "black",
    paddingBottom: 10,
  },
  iconEdit: {
    color: "#2b323c",
    marginTop: 2,
  },
  textEdit:{
    color: "#2b323c",
    fontSize: "16px",
    fontWeight: "bold",
  },
  referencia: {
    fontSize: "16px",
    fontWeight: "400",
    color: "black",
  },
  required: {
    color: "red",
  },
  field: {
    paddingTop: 6,
  },
  iconHelp: {
    color: "#5B6670",
    marginTop: 20,
  },
  gridMain: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  gridReferencia: {
    paddingTop: 30,
  },
  gridInputField: {
    paddingBottom: 5,
  },
  gridLabel: {
    paddingTop: 20,
  },
  gridSectionImages: {
    padding: 20,
  },
  imageReference: {
    textAlign: "center",
  },
  imgLargo: {
    position: "relative",
    paddingTop: 10,
  },
  imgAltura: {
    position: "relative",
    paddingTop: 10,
  },
  imgModulacion: {
    position: "relative",
    paddingTop: 10,
  },
}));

const DesignInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "18px 26px 18px 12px",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
