import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  subtitle: {
    textAlign: "justify",
    fontSize: 18,
    fontWeight: "bold",
  },
  parrafo: {
    textAlign: "justify",
    fontSize: 16,
    paddingTop: theme.spacing(2),
  },
  link: {
    textDecoration : "none",
  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.header} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    justifyContent: "center",
  },
}))(MuiDialogActions);

export default function TerminosyCondicionesComponent(props) {
  const { open, handleClose, title } = props;
  const classes = useStyles();


  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Términos y Condiciones
        </DialogTitle>
        <DialogContent dividers>
          <Typography className={classes.subtitle} gutterBottom>
            Términos de uso de <a style={{textDecoration:"none"}} href="http://adbarbieri.com" target="_blank">adbarbieri.com</a>
          </Typography>
          <Typography className={classes.parrafo} gutterBottom>
            1. Las condiciones de Protección de Datos (ley 25.326) para uso
            exclusivo y privado de A. D. Barbieri S. A.
          </Typography>
          <Typography className={classes.parrafo} gutterBottom>
            2. Que la información suministrada es un cómputo estimativo de
            materiales, siendo de exclusiva responsabilidad de un profesional
            habilitado el cálculo definitivo.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            CERRAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
