import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Select from "@material-ui/core/Select";
import { Divider } from "@material-ui/core";
import getProvinciasService from "../../components/Services/getProvinciasService";
import Button from "@material-ui/core/Button";

import { INPUTS_STEEL_FRAME_URI } from "../../routes";
import { HOME_URI } from "../../routes";
export const STEEL_FRAME_DATA = "steelFrameData";

function SteelFramePage(props) {
  const classes = useStyles();
  const [messageError, setMessageError] = React.useState("");
  const [provincias, setProvincias] = React.useState([]);

  const [formData, setFormData] = React.useState({
    cantidadPlantas: 1,
    calculaTanque: "0",
    zonaGeografica: "",
    exposicionViento: "",
    tipoCubierta: "",
    tipoEntrepiso: "",
    tipoApoyoTanque: "entrepiso-tanque",
  });

  const cantPlantas = [
    { id: 1, description: "1 planta", value: 1 },
    { id: 2, description: "2 plantas", value: 2 },
  ];

  const selectTanqueReserva = [
    { id: 1, description: "Sí", value: "1" },
    { id: 2, description: "No", value: "0" },
  ];

  const tiposExposiciones = [
    { id: 1, description: "Urbano", value: "urbano" },
    { id: 2, description: "Descampado", value: "descampado" },
  ];

  const tiposCubiertas = [
    { id: 1, description: "Cubierta inclinada", value: "cubierta-inclinada" },
    {
      id: 2,
      description: "Cubierta húmeda no accesible",
      value: "cubierta-humeda-no-accesible",
    },
    {
      id: 3,
      description: "Cubierta húmeda accesible",
      value: "cubierta-humeda-accesible",
    },
    {
      id: 4,
      description: "No aplica",
      value: "no-aplica",
    },
  ];

  const tiposEntrepisos = [
    { id: 1, description: "Entrepiso seco", value: "entrepiso-seco" },
    {
      id: 2,
      description: "Entrepiso húmedo pesado",
      value: "entrepiso-humedo-pesado",
    },
    {
      id: 3,
      description: "Entrepiso húmedo liviano",
      value: "entrepiso-humedo-liviano",
    },
    {
      id: 4,
      description: "No aplica",
      value: "no-aplica",
    },
  ];

  const tiposApoyosTanques = [
    { id: 1, description: "Entrepiso tanque", value: "entrepiso-tanque" },
  ];

  const DesignInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "18px 26px 18px 12px",
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);

  useEffect(() => {
    let steelFrameData = JSON.parse(sessionStorage.getItem(STEEL_FRAME_DATA));

    if (steelFrameData) {
      setFormData(steelFrameData);
    }

    if (provincias.length === 0) {
      getProvinciasService()
        .then((response) => {
          setProvincias(response);
        })
        .catch(function (error) {
          console.log("Error al cargar los sistemas");
          setMessageError(
            "Disculpe las molestias ocasionadas. Estamos trabajando para reestablecer la plataforma."
          );
        });
    }
  }, [setProvincias]);

  function handleInputChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const redirectInicio = () => {
    props.history.push(HOME_URI);
  };

  const redirectInputs = () => {
    props.history.push(INPUTS_STEEL_FRAME_URI);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sessionStorage.setItem(STEEL_FRAME_DATA, JSON.stringify(formData));

    redirectInputs();
  };

  return (
    <div className={classes.root}>
      <div className={classes.containerHome}>
        <Typography className={classes.titleHome}>
          Sistema Steel Frame
        </Typography>
        <div className={classes.divider}>
          <Divider />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={classes.containerForm}>
          <Grid container spacing={5} className={classes.sectionInit}>
            <Grid className={classes.gridCantidadPlantas} item md={6} sm={12}>
              <Typography className={classes.label}>
                ¿Cuántas plantas desea calcular?
              </Typography>

              <Select
                native
                className={classes.autocomplete}
                value={formData.cantidadPlantas}
                onChange={(e) => handleInputChange(e)}
                required
                input={<DesignInput />}
                fullWidth
                inputProps={{
                  name: "cantidadPlantas",
                  id: "outlined-age-native-simple",
                }}
              >
                {cantPlantas &&
                  cantPlantas.map((planta, i) => (
                    <option key={i} value={planta.value}>
                      {planta.description}
                    </option>
                  ))}
              </Select>
            </Grid>

            <Grid className={classes.gridTanqueReserva} item md={6} sm={12}>
              <Typography className={classes.label}>
                ¿Desea calcular tanque de reserva?
              </Typography>
              <Select
                native
                className={classes.autocomplete}
                value={formData.calculaTanque}
                onChange={(e) => handleInputChange(e)}
                required
                input={<DesignInput />}
                fullWidth
                inputProps={{
                  name: "calculaTanque",
                  id: "outlined-calcula-tanque",
                }}
              >
                {selectTanqueReserva &&
                  selectTanqueReserva.map((sel, i) => (
                    <option key={i} value={sel.value}>
                      {sel.description}
                    </option>
                  ))}
              </Select>
            </Grid>
          </Grid>

          <Grid container spacing={5} className={classes.sectionGeo}>
            <Grid className={classes.gridZonaGeo} item md={6} sm={12}>
              <Typography className={classes.label}>
                <span className={classes.required}>*</span> Zona geográfica
              </Typography>

              <Select
                native
                className={classes.autocomplete}
                value={formData.zonaGeografica}
                onChange={(e) => handleInputChange(e)}
                required
                input={<DesignInput />}
                fullWidth
                inputProps={{
                  name: "zonaGeografica",
                  id: "outlined-age-native-simple",
                }}
              >
                <option value="">Seleccionar</option>
                {provincias &&
                  provincias.map((provincia, i) => (
                    <option key={i} value={provincia.id}>
                      {provincia.descripcion}
                    </option>
                  ))}
              </Select>
            </Grid>
            <Grid className={classes.gridExpoViento} item md={6} sm={12}>
              <Typography className={classes.label}>
                <span className={classes.required}>*</span> Exposición del
                viento
              </Typography>

              <Select
                native
                className={classes.autocomplete}
                value={formData.exposicionViento}
                onChange={(e) => handleInputChange(e)}
                required
                input={<DesignInput />}
                fullWidth
                inputProps={{
                  name: "exposicionViento",
                  id: "outlined-age-native-simple",
                }}
              >
                <option value="">Seleccionar</option>
                {tiposExposiciones &&
                  tiposExposiciones.map((exposicion, i) => (
                    <option key={i} value={exposicion.value}>
                      {exposicion.description}
                    </option>
                  ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={5} className={classes.sectionCubierta}>
            <Grid className={classes.gridZonaGeo} item md={6} sm={12}>
              <Typography className={classes.label}>
                <span className={classes.required}>*</span> Tipo de cubierta
              </Typography>

              <Select
                native
                className={classes.autocomplete}
                value={formData.tipoCubierta}
                onChange={(e) => handleInputChange(e)}
                required
                input={<DesignInput />}
                fullWidth
                inputProps={{
                  name: "tipoCubierta",
                  id: "outlined-age-native-simple",
                }}
              >
                <option value="">Seleccionar</option>
                {tiposCubiertas &&
                  tiposCubiertas.map((tipoCubierta, i) => (
                    <option key={i} value={tipoCubierta.value}>
                      {tipoCubierta.description}
                    </option>
                  ))}
              </Select>
            </Grid>
            {formData.cantidadPlantas === "2" ? <Grid className={classes.gridZonaGeo} item md={6} sm={12}>
                <Typography className={classes.label}>
                  <span className={classes.required}>*</span> Tipo de entrepiso
                </Typography>

                <Select
                  native
                  className={classes.autocomplete}
                  value={formData.tipoEntrepiso}
                  onChange={(e) => handleInputChange(e)}
                  required
                  input={<DesignInput />}
                  fullWidth
                  inputProps={{
                    name: "tipoEntrepiso",
                    id: "outlined-age-native-simple",
                  }}
                >
                  <option value="">Seleccionar</option>
                  {tiposEntrepisos &&
                    tiposEntrepisos.map((tiposEntrepiso, i) => (
                      <option key={i} value={tiposEntrepiso.value}>
                        {tiposEntrepiso.description}
                      </option>
                    ))}
                </Select>
              </Grid> : <Grid /> }
          </Grid>

          <Grid container className={classes.containerButtons} spacing={10}>
            <Grid className={classes.buttonBack} item xs={6}>
              <ButtonBack
                onClick={() => redirectInicio()}
                color="default"
                variant="outlined"
                className={classes.button}
              >
                Atrás
              </ButtonBack>
            </Grid>
            <Grid className={classes.buttonInput} item xs={6}>
              <ButtonSubmit
                type="submit"
                value="Submit"
                color="secondary"
                variant="contained"
                className={classes.button}
              >
                Continuar
              </ButtonSubmit>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
}

export default SteelFramePage;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 5,
    overflow: "hidden",
    paddingLeft: '20%',
    paddingRight: '20%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '10%',
      paddingRight: '10%',
    },
  },
  titleHome: {
    fontSize: "28px",
    fontWeight: "bold",
    color: "#022A3A",
  },
  containerHome: {
    position: "relative",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
  },
  containerForm: {
    position: "relative",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  title: {
    fontSize: "20px",
    fontWeight: "600",
    color: theme.palette.secondary.main,
  },
  label: {
    fontSize: "18px",
    fontWeight: "400",
    color: "black",
    paddingBottom: 10,
  },
  autocomplete: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  gridTanqueReserva: {
    paddingTop: 20,
    paddingBottom: 10,
  },
  sectionGeo: {
    paddingTop: 20,
    paddingBottom: 10,
  },
  sectionInit: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  required: {
    color: "red",
  },
  sectionCubierta: {
    paddingTop: 20,
    paddingBottom: 10,
  },
  field: {
    paddingTop: 6,
  },
  sectionEntrepiso: {
    paddingTop: 20,
    paddingBottom: 10,
  },
  sectionTanque: {
    paddingTop: 20,
    paddingBottom: 10,
  },

  buttonBack: {
    textAlign: "right",
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  buttonInput: {
    textAlign: "left",
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  containerButtons: {
    paddingTop: 100,
    paddingBottom: 50,
  },
  button: {
    fontSize: "20px",
    padding: "10px 90px 10px 90px",
    borderRadius: "7px",
  },
  divider: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const ButtonSubmit = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "white",
    backgroundColor: "#022A3A",
    borderColor: "#022A3A",
    "&:hover": {
      backgroundColor: "#022a44",
      borderColor: "#022a44",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#022a44",
      borderColor: "#022a44",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(2,42,58,.5)",
    },
  },
})(Button);

const ButtonBack = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    width: "220px",
    fontSize: 20,
    padding: "10px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "#5B6670",
    backgroundColor: "transparent",
    borderColor: "#5B6670",
    "&:hover": {
      backgroundColor: "rgba(91,102,112,.1)",
      borderColor: "#5B6670",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "transparent",
      borderColor: "#5B6670",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(91,102,112,.5)",
    },
  },
})(Button);
