import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#022A3A',
      light: '#053D54'
    },
    white: {
      text: "#fff"
    },
    secondary: {
      main: '#E2231A',
    },
    default: {
      main: '#5B6670',
    },
    background: {
      main: '#FBFBFB',
    },
    forenground: {
      main: '#2b323c',
    },
    yellow: {
      main: '#FDE300'
    },
    pantone: {
      main: 'RGB(151,160,164,0.6)',
      light: 'RGB(151,160,164,0.7)'
    }
  }
});

export default theme;

